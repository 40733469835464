import StyledIcon from 'components/styledIcon';
import type { IconProps } from 'components/icons/IconProps';
import { Icon as IconType } from '../../types/Document';
import { forwardRef, type FC, lazy, Suspense, useMemo } from 'react';
import { useDocumentContentStyle } from 'pages/procedures/hooks/useDocumentContentStyle';

const QuestionIcon = lazy(() => import('components/icons/QuestionIcon'));

const Icons: {
  [identifier: string]: FC<IconProps>;
} = {
  question: QuestionIcon,
  // ... additional supported icons
};

const IconComponent = forwardRef<HTMLDivElement, Omit<IconType, 'contentType'>>(({ iconIdentifier, style }, ref) => {
  const styleProp = useDocumentContentStyle({ style });

  const Icon = useMemo(() => Icons[iconIdentifier], [iconIdentifier]);

  return (
    <div style={styleProp} ref={ref} className="icon">
      <Suspense fallback={<StyledIcon svg={null} />}>
        <StyledIcon svg={<Icon color="#6C2D91" />} />
      </Suspense>
    </div>
  );
});

export default IconComponent;
