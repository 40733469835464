import { useEffect, useRef, useState } from 'react';

type UseCyclicalFadeEffectProps = {
  /** The callback to be ran each time the sequence is repeated between; use this to update your component's state, such as a current index for an array of content */
  onNext: () => void;
  /** The duration that animation state is 'fadeIn'; the total time between idle states is 2 * this duration */
  animationIdleMilliseconds: number;
  /** The duration of animation between states; this will be 1/2 the time it takes to transition between idle states */
  fadeTransitionMilliseconds: number;
};

/**
 * @title useCyclicalFadeEffect
 *
 * @description This hook manages a fade in/out sequence. Consuming the hook merely tells your component when something
 * is on screen (`'fadeIn'`) and when it is not (`'fadeOut'`). It boils all of the congnitive overhead of managing
 * timers down into two possible states: `'fadeIn'` and `'fadeOut'`.
 *
 * The hook makes no assumptions about what exactly is being faded in/out, so you can use any set of CSS
 * transitions or animations (i.e. transforming offscreen or opacity, or both!) to achieve your desired
 * effect. The goal is to gracefully remove content from the user's view before cutting over to the next
 * piece of content (something that would jarring if it were to happen on-screen), and then gracefully
 * fading the new content in.
 *
 * The hook also allows you to configure the duration of the transition, and the duration of the idle time
 * between fade effects.
 *
 * The end state of your `'fadeIn'` CSS transition / animation is assumed to be fully opaque or onscreen somehow,
 * and the end state of your `'fadeOut'` CSS transition / animation is assumed to be fully transparent or offscreen
 * somehow.
 *
 * The sequence is as follows:
 *
 * 1. Fade in for `fadeTransitionMilliseconds`
 * 2. Wait for `animationIdleMilliseconds`
 * 3. Fade out for `fadeTransitionMilliseconds`
 * 4. Call the consumer's content update `onNext` callback (assuming the end state of your `'fadeOut'` CSS
 *    animation / transition is fully transparent or offscreen, it's safe to update your content here
 *    without jarring effects)
 * 5. Fade in for `fadeTransitionMilliseconds`
 *
 */
export const useCyclicalFadeEffect = ({
  onNext,
  animationIdleMilliseconds,
  fadeTransitionMilliseconds,
}: UseCyclicalFadeEffectProps): 'fadeIn' | 'fadeOut' => {
  const [uiAnimationState, setUIAnimationState] = useState<'fadeIn' | 'fadeOut'>('fadeOut');

  const animationTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const fadeIn = () => {
      enqueueFadeOut();
      setUIAnimationState('fadeIn');
    };

    const fadeOut = () => {
      enqueueCycleThrough();
      setUIAnimationState('fadeOut');
    };

    const cycleThrough = () => {
      fadeIn();
      onNext();
    };

    const enqueueCycleThrough = () => {
      if (animationTimeout.current) clearTimeout(animationTimeout.current);
      animationTimeout.current = setTimeout(cycleThrough, fadeTransitionMilliseconds);
    };

    const enqueueFadeOut = () => {
      if (animationTimeout.current) clearTimeout(animationTimeout.current);
      animationTimeout.current = setTimeout(fadeOut, animationIdleMilliseconds);
    };

    /** Kick off sequence */
    fadeIn();

    /** Clean up timers on unmount */
    return () => {
      if (animationTimeout.current) clearTimeout(animationTimeout.current);
    };
  }, [animationIdleMilliseconds, fadeTransitionMilliseconds, onNext]);

  return uiAnimationState;
};
