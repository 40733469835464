import { type FC } from 'react';

type Props = {
  /* The link href to the social media page */
  link: string;
  /* The svg to display to be loaded as a string into CSS background-image */
  svg: string;
  /** Accessibility label text */
  labelText: string;
};

const SocialMediaLink: FC<Props> = ({ link, labelText, svg }: Props) => (
  <a
    role="link"
    href={link}
    target="_blank"
    aria-label={labelText}
    rel="noreferrer noopener"
    className="social-media-link"
  >
    <div className="social-media-logo" style={{ backgroundImage: `url(${svg})` }} />
  </a>
);

export default SocialMediaLink;
