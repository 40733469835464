import './Testimonials.scss';
import classNames from 'classnames';
import docText from '../../i18n/documentText.json';
import { useCallback, useState, type FC } from 'react';
import { useCyclicalFadeEffect } from '../../hooks/useCyclicalFadeEffect';

const ANIMATION_IDLE_MILLISECONDS = 5000;
const FADE_TRANSITION_MILLISECONDS = 750;

type Testimonial = {
  /** Name of the testimonial author */
  author: string;
  /** The testimonial / review to display */
  testimonial: string;
  /** A description relaying the credibility / relationship of the author i.e. Patient for over 30 years */
  authorAttribution: string;
};

type TestimonialsProps = {
  testimonials: Testimonial[];
};

const Testimonials: FC<TestimonialsProps> = ({ testimonials }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = useCallback(() => {
    /** If the end has been reached, cycle back to beginning */
    setCurrentIndex((currentIndex) => (currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1));
  }, [testimonials.length]);

  const uiAnimationState = useCyclicalFadeEffect({
    animationIdleMilliseconds: ANIMATION_IDLE_MILLISECONDS,
    fadeTransitionMilliseconds: FADE_TRANSITION_MILLISECONDS,

    onNext: nextTestimonial,
  });

  return (
    <section className="testimonials-container">
      <div
        className={classNames('testimonials', uiAnimationState)}
        style={{
          transitionDuration: `${FADE_TRANSITION_MILLISECONDS}ms`,
        }}
      >
        <div className="testimonial-card">
          <p className="card-label">{docText.testimonials.label}</p>
          <p className="testimonial-text">{testimonials[currentIndex].testimonial}</p>
          <p className="testimonial-author">{testimonials[currentIndex].author}</p>
          <p className="testimonial-attribution">{testimonials[currentIndex].authorAttribution}</p>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
