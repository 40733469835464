import { useMemo } from 'react';
import { useLayout } from 'context/Layout/useLayout';
import type { Style } from '../../../components/Document/types/Document';

export type UseDocumentContentStyleHookProps = {
  style?: Style;
};

export const useDocumentContentStyle = ({ style }: UseDocumentContentStyleHookProps) => {
  const { layout } = useLayout();

  const styleProp = useMemo(() => {
    if (!style || !layout) return {};

    /** Narrow type to CSSProperties object or mediaQueries object */
    if (!('mediaQueries' in style)) return style;

    /**
     * Return the styles for the current layout; if all is included,
     * ayout-specific styles override `all` styles.
     */
    return {
      ...(style.mediaQueries.all ?? {}),
      ...(style.mediaQueries[layout] ?? {}),
    };
  }, [style, layout]);

  return styleProp;
};
