import './ImageSection.scss';
import classNames from 'classnames';
import { type FC, type ReactNode, type HTMLAttributes } from 'react';

export type ImageSectionProps = HTMLAttributes<HTMLDivElement> & {
  imageSrc: string;
  imageAlt: string;
  imagePlacement: 'left' | 'right';
  children: ReactNode | ReactNode[];
};

const ImageSection: FC<ImageSectionProps> = ({ imageSrc, imageAlt, imagePlacement, children, className }) => (
  <section className={classNames('section', className)}>
    <div className={classNames('flex-container', imagePlacement === 'left' ? 'row' : 'row-reverse')}>
      <img src={imageSrc} alt={imageAlt} />

      <div className="section-content">{children}</div>
    </div>
  </section>
);

export default ImageSection;
