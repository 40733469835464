import type { ReactNode } from 'react';
import type { Hyperlink } from '../types';
import { Button } from '../components/button';
import { useNavigate } from 'react-router-dom';
import ToothX from '../components/icons/ToothX';
import docText from '../i18n/documentText.json';
import StyledIcon from '../components/styledIcon';
import ToothSmiling from '../components/icons/ToothSmiling';
import ToothBrowser from '../components/icons/ToothBrowser';
import ArrowLeftIcon from '../svg/left-arrow-button-adornment.svg';
import ToothSpeechBubble from '../components/icons/ToothSpeechBubble';
import ArrowRightIconPurple from '../svg/right-arrow-button-adornment-purple.svg';

const helpfulLinksWithIcons: {
  [key: string]: Hyperlink & {
    title: string;
    description: string;
    icon: ReactNode;
  };
} = {
  link1: { ...docText.pageNotFound.links.link1, icon: <ToothSmiling /> },
  link2: { ...docText.pageNotFound.links.link2, icon: <ToothBrowser /> },
  link3: { ...docText.pageNotFound.links.link3, icon: <ToothSpeechBubble /> },
};

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <main className="page-not-found-container">
      <StyledIcon svg={<ToothX />} />
      <h1 className="page-not-found-title">{docText.pageNotFound.hero.h1}</h1>
      <p className="page-not-found-text">{docText.pageNotFound.hero.text1}</p>
      <p className="page-not-found-text">{docText.pageNotFound.hero.text2}</p>

      <section className="page-not-found-buttons-container">
        <Button
          height={60}
          variant="outlined"
          onClick={() => navigate(-1)}
          className="page-not-found-buttons"
          adornmentLeft={
            <div
              className="page-not-found-arrow-left-adornment"
              style={{
                backgroundImage: `url(${ArrowLeftIcon})`,
              }}
            />
          }
        >
          {docText.pageNotFound.hero.backButton}
        </Button>

        <Button height={60} linkTo="/" className="page-not-found-buttons">
          {docText.pageNotFound.hero.ctaButton}
        </Button>
      </section>

      <section className="page-not-found-links-container">
        <ul className="page-not-found-links">
          {Object.keys(helpfulLinksWithIcons).map((key) => (
            <li key={key} className="page-not-found-link">
              <StyledIcon svg={helpfulLinksWithIcons[key].icon} />
              <h2 className="page-not-found-link-title">{helpfulLinksWithIcons[key].title}</h2>
              <p className="page-not-found-link-description">{helpfulLinksWithIcons[key].description}</p>
              <Button
                variant="underline"
                className="page-not-found-link-button"
                linkTo={helpfulLinksWithIcons[key].link}
                adornmentRight={
                  <div
                    className="page-not-found-link-arrow"
                    style={{ backgroundImage: `url(${ArrowRightIconPurple})` }}
                  />
                }
              >
                {helpfulLinksWithIcons[key].labelText}
              </Button>
            </li>
          ))}
        </ul>
      </section>
    </main>
  );
};

export default PageNotFound;
