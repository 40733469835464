import { type ReactNode } from 'react';

export enum Layouts {
  Desktop = 'desktop',
  Tablet = 'tablet',
  Mobile = 'mobile',
}

export type LayoutContextValue = {
  layout: Layouts | null;
  windowInnerWidth: number;
  windowInnerHeight: number;
};
export type LayoutContextProviderProps = {
  children: ReactNode | ReactNode[];
};
