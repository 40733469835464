const Logo = ({ textColor = 'black' }: { textColor: string }) => (
  <svg width="302" height="36" viewBox="0 0 302 36" fill="none">
    <path
      d="M34.0828 0L0 34.4024L29.929 16.8284L35.1479 19.5976L42.284 13.5266L86.4852 32.1657L67.4201 13.5266L61.0296 19.1716L48.6746 6.39053L46.0118 9.79882L34.0828 0Z"
      fill="#8738B5"
    />
    <path
      d="M43.2426 20.9822L3.94083 36L41.7515 27.9053L83.1834 33.9763L55.7041 23.3254L51.1243 25.3491L43.2426 20.9822Z"
      fill="#8738B5"
    />
    <path
      d="M98.3004 23.52C98.3004 23.6 98.3244 23.664 98.3724 23.712C98.4364 23.744 98.5564 23.76 98.7324 23.76V24.048H94.4844V23.76C94.6604 23.728 94.7884 23.68 94.8684 23.616C94.9484 23.536 95.0284 23.4 95.1084 23.208C95.1404 23.128 95.2204 22.9119 95.3484 22.5599C95.4764 22.2079 95.6444 21.768 95.8524 21.24C96.0604 20.696 96.2924 20.096 96.5484 19.44C96.8044 18.784 97.0604 18.12 97.3164 17.448C97.5724 16.76 97.8204 16.096 98.0604 15.456C98.3164 14.8 98.5404 14.216 98.7324 13.7039C98.9244 13.1919 99.0764 12.776 99.1884 12.456C99.3164 12.136 99.3804 11.968 99.3804 11.952C99.3804 11.872 99.3244 11.816 99.2124 11.7839C99.1164 11.752 99.0124 11.728 98.9004 11.712V11.424H105.164V11.712C105.036 11.728 104.932 11.752 104.852 11.7839C104.772 11.816 104.732 11.896 104.732 12.0239C104.732 12.056 104.804 12.264 104.948 12.648C105.108 13.0319 105.308 13.536 105.548 14.16C105.788 14.768 106.06 15.464 106.364 16.248C106.684 17.016 107.012 17.808 107.348 18.624C107.684 19.424 108.004 20.208 108.308 20.976C108.628 21.744 108.916 22.432 109.172 23.04C109.3 23.328 109.404 23.52 109.484 23.616C109.58 23.712 109.7 23.76 109.844 23.76V24.048H105.116V23.76C105.42 23.744 105.572 23.648 105.572 23.472C105.572 23.456 105.532 23.352 105.452 23.16C105.372 22.952 105.284 22.728 105.188 22.488C105.092 22.232 104.996 21.992 104.9 21.768C104.82 21.544 104.772 21.392 104.756 21.312H99.0684C98.9084 21.744 98.7804 22.096 98.6844 22.368C98.5884 22.64 98.5084 22.856 98.4444 23.016C98.3964 23.176 98.3564 23.2959 98.3244 23.3759C98.3084 23.4399 98.3004 23.488 98.3004 23.52ZM104.132 19.392L101.804 13.68L99.6684 19.392H104.132Z"
      fill={textColor}
    />
    <path
      d="M112.885 14.208C112.885 14.032 112.821 13.92 112.693 13.872C112.565 13.824 112.413 13.7919 112.237 13.7759V13.488H116.725V13.7759C116.549 13.7919 116.397 13.824 116.269 13.872C116.157 13.92 116.101 14.032 116.101 14.208V21.6H122.677C122.773 21.6 122.869 21.568 122.965 21.504C123.061 21.424 123.109 21.256 123.109 21H123.397V24.576H123.109C123.109 24.32 123.061 24.168 122.965 24.12C122.869 24.072 122.773 24.048 122.677 24.048H112.237V23.76C112.413 23.744 112.565 23.7119 112.693 23.6639C112.821 23.6159 112.885 23.504 112.885 23.328V14.208Z"
      fill={textColor}
    />
    <path
      d="M135.869 13.488C135.965 13.488 136.061 13.4559 136.157 13.3919C136.253 13.328 136.301 13.184 136.301 12.96H136.589V16.464H136.301C136.301 16.24 136.253 16.096 136.157 16.0319C136.061 15.9679 135.965 15.936 135.869 15.936H132.437V23.328C132.437 23.504 132.493 23.6159 132.605 23.6639C132.733 23.7119 132.861 23.744 132.989 23.76V24.048H128.669V23.76C128.797 23.744 128.917 23.7119 129.029 23.6639C129.157 23.6159 129.221 23.504 129.221 23.328V15.936H125.789C125.693 15.936 125.597 15.9679 125.501 16.0319C125.405 16.096 125.357 16.24 125.357 16.464H125.069V12.96H125.357C125.357 13.184 125.405 13.328 125.501 13.3919C125.597 13.4559 125.693 13.488 125.789 13.488H135.869Z"
      fill={textColor}
    />
    <path
      d="M143.877 13.7759C143.637 13.7919 143.445 13.824 143.301 13.872C143.173 13.92 143.109 14.032 143.109 14.208V23.328C143.109 23.504 143.173 23.6159 143.301 23.6639C143.445 23.7119 143.637 23.744 143.877 23.76V24.048H139.125V23.76C139.365 23.744 139.549 23.7119 139.677 23.6639C139.821 23.6159 139.893 23.504 139.893 23.328V14.208C139.893 14.032 139.821 13.92 139.677 13.872C139.549 13.824 139.365 13.7919 139.125 13.7759V13.488H143.877V13.7759Z"
      fill={textColor}
    />
    <path
      d="M157.197 13.488C157.293 13.488 157.389 13.4559 157.485 13.3919C157.581 13.328 157.629 13.184 157.629 12.96H157.917V16.464H157.629C157.629 16.24 157.581 16.096 157.485 16.0319C157.389 15.9679 157.293 15.936 157.197 15.936H153.765V23.328C153.765 23.504 153.821 23.6159 153.933 23.6639C154.061 23.7119 154.189 23.744 154.317 23.76V24.048H149.997V23.76C150.125 23.744 150.245 23.7119 150.357 23.6639C150.485 23.6159 150.549 23.504 150.549 23.328V15.936H147.117C147.021 15.936 146.925 15.9679 146.829 16.0319C146.733 16.096 146.685 16.24 146.685 16.464H146.397V12.96H146.685C146.685 13.184 146.733 13.328 146.829 13.3919C146.925 13.4559 147.021 13.488 147.117 13.488H157.197Z"
      fill={textColor}
    />
    <path
      d="M161.197 14.208C161.197 14.032 161.125 13.92 160.981 13.872C160.853 13.824 160.693 13.7919 160.501 13.7759V13.488H164.821V13.7759C164.741 13.7919 164.653 13.824 164.557 13.872C164.461 13.92 164.413 14.032 164.413 14.208V20.04C164.413 20.648 164.629 21.088 165.061 21.36C165.509 21.616 166.093 21.7439 166.813 21.7439C167.533 21.7439 168.109 21.616 168.541 21.36C168.989 21.088 169.213 20.648 169.213 20.04V14.208C169.213 14.032 169.165 13.92 169.069 13.872C168.973 13.824 168.885 13.7919 168.805 13.7759V13.488H173.125V13.7759C172.933 13.7919 172.765 13.824 172.621 13.872C172.493 13.92 172.429 14.032 172.429 14.208V19.488C172.429 20.128 172.333 20.736 172.141 21.312C171.949 21.872 171.637 22.368 171.205 22.8C170.773 23.216 170.197 23.544 169.477 23.784C168.757 24.008 167.869 24.12 166.813 24.12C165.757 24.12 164.869 24.008 164.149 23.784C163.429 23.544 162.853 23.216 162.421 22.8C161.989 22.368 161.677 21.872 161.485 21.312C161.293 20.736 161.197 20.128 161.197 19.488V14.208Z"
      fill={textColor}
    />
    <path
      d="M181.86 21.7439C183.108 21.7439 184.004 21.528 184.549 21.096C185.092 20.648 185.365 19.872 185.365 18.768C185.365 17.664 185.092 16.896 184.549 16.464C184.004 16.016 183.108 15.792 181.86 15.792H180.132V21.7439H181.86ZM176.916 14.208C176.916 14.032 176.853 13.92 176.725 13.872C176.613 13.824 176.468 13.7919 176.292 13.7759V13.488H183.493C184.389 13.488 185.148 13.632 185.772 13.92C186.396 14.208 186.909 14.592 187.309 15.072C187.725 15.552 188.021 16.112 188.197 16.752C188.389 17.392 188.484 18.064 188.484 18.768C188.484 19.472 188.389 20.144 188.197 20.784C188.021 21.424 187.725 21.984 187.309 22.464C186.909 22.944 186.396 23.328 185.772 23.616C185.148 23.904 184.389 24.048 183.493 24.048H176.292V23.76C176.468 23.744 176.613 23.7119 176.725 23.6639C176.853 23.6159 176.916 23.504 176.916 23.328V14.208Z"
      fill={textColor}
    />
    <path
      d="M201.668 21.936C201.988 21.936 202.196 21.736 202.292 21.336H202.58V24.4799H202.34C202.26 24.2559 202.172 24.128 202.076 24.096C201.996 24.064 201.86 24.048 201.668 24.048H190.964V23.76C191.14 23.744 191.292 23.7119 191.42 23.6639C191.564 23.6159 191.636 23.504 191.636 23.328V14.208C191.636 14.032 191.564 13.92 191.42 13.872C191.292 13.824 191.14 13.7919 190.964 13.7759V13.488H201.86C201.956 13.488 202.052 13.4559 202.148 13.3919C202.244 13.328 202.292 13.184 202.292 12.96H202.58V16.176H202.292C202.292 15.936 202.244 15.784 202.148 15.72C202.052 15.64 201.956 15.5999 201.86 15.5999H194.852V17.544H199.364C199.46 17.544 199.556 17.512 199.652 17.448C199.748 17.368 199.796 17.216 199.796 16.992H200.084V20.112H199.796C199.796 19.888 199.748 19.744 199.652 19.68C199.556 19.6 199.46 19.56 199.364 19.56H194.852V21.936H201.668Z"
      fill={textColor}
    />
    <path
      d="M220.749 21.216C222.013 21.216 222.925 20.896 223.485 20.256C224.045 19.6 224.325 18.76 224.325 17.736C224.325 16.712 224.045 15.88 223.485 15.24C222.925 14.584 222.013 14.2559 220.749 14.2559H217.701V21.216H220.749ZM214.101 12.144C214.101 12.032 214.061 11.944 213.981 11.88C213.917 11.8 213.733 11.744 213.429 11.712V11.424H221.397C222.501 11.424 223.461 11.584 224.277 11.9039C225.093 12.2239 225.765 12.672 226.293 13.248C226.821 13.808 227.213 14.472 227.469 15.24C227.741 16.008 227.877 16.84 227.877 17.736C227.877 18.552 227.733 19.344 227.445 20.112C227.173 20.864 226.749 21.536 226.173 22.128C225.613 22.704 224.909 23.168 224.061 23.52C223.229 23.872 222.253 24.048 221.133 24.048H213.429V23.76C213.733 23.728 213.917 23.68 213.981 23.616C214.061 23.536 214.101 23.44 214.101 23.328V12.144Z"
      fill={textColor}
    />
    <path
      d="M240.973 21.936C241.293 21.936 241.501 21.736 241.597 21.336H241.885V24.4799H241.645C241.565 24.2559 241.477 24.128 241.381 24.096C241.301 24.064 241.165 24.048 240.973 24.048H230.269V23.76C230.445 23.744 230.597 23.7119 230.725 23.6639C230.869 23.6159 230.941 23.504 230.941 23.328V14.208C230.941 14.032 230.869 13.92 230.725 13.872C230.597 13.824 230.445 13.7919 230.269 13.7759V13.488H241.165C241.261 13.488 241.357 13.4559 241.453 13.3919C241.549 13.328 241.597 13.184 241.597 12.96H241.885V16.176H241.597C241.597 15.936 241.549 15.784 241.453 15.72C241.357 15.64 241.261 15.5999 241.165 15.5999H234.157V17.544H238.669C238.765 17.544 238.861 17.512 238.957 17.448C239.053 17.368 239.101 17.216 239.101 16.992H239.389V20.112H239.101C239.101 19.888 239.053 19.744 238.957 19.68C238.861 19.6 238.765 19.56 238.669 19.56H234.157V21.936H240.973Z"
      fill={textColor}
    />
    <path
      d="M257.373 23.328C257.373 23.504 257.437 23.6159 257.565 23.6639C257.693 23.7119 257.853 23.744 258.045 23.76V24.048H253.605V23.76C253.733 23.76 253.877 23.744 254.037 23.712C254.213 23.68 254.301 23.592 254.301 23.448C254.301 23.304 254.125 23.04 253.773 22.656C253.421 22.256 252.965 21.7679 252.405 21.1919C251.861 20.6159 251.245 19.976 250.557 19.272C249.869 18.552 249.189 17.792 248.517 16.992L248.469 17.04V23.328C248.469 23.504 248.525 23.6159 248.637 23.6639C248.765 23.7119 248.917 23.744 249.093 23.76V24.048H244.989V23.76C245.149 23.744 245.285 23.7119 245.397 23.6639C245.525 23.6159 245.589 23.504 245.589 23.328V14.208C245.589 14.032 245.525 13.92 245.397 13.872C245.269 13.824 245.117 13.7919 244.941 13.7759V13.488H250.653V13.7759C250.541 13.7919 250.429 13.824 250.317 13.872C250.205 13.92 250.149 14.032 250.149 14.208C250.149 14.24 250.277 14.408 250.533 14.712C250.805 15 251.149 15.368 251.565 15.816C251.981 16.264 252.445 16.76 252.957 17.304C253.469 17.848 253.981 18.392 254.493 18.936V14.208C254.493 14.032 254.429 13.92 254.301 13.872C254.173 13.824 254.013 13.7919 253.821 13.7759V13.488H258.045V13.7759C257.853 13.7919 257.693 13.824 257.565 13.872C257.437 13.92 257.373 14.032 257.373 14.208V23.328Z"
      fill={textColor}
    />
    <path
      d="M271.197 13.488C271.293 13.488 271.389 13.4559 271.485 13.3919C271.581 13.328 271.629 13.184 271.629 12.96H271.917V16.464H271.629C271.629 16.24 271.581 16.096 271.485 16.0319C271.389 15.9679 271.293 15.936 271.197 15.936H267.765V23.328C267.765 23.504 267.821 23.6159 267.933 23.6639C268.061 23.7119 268.189 23.744 268.317 23.76V24.048H263.997V23.76C264.125 23.744 264.245 23.7119 264.357 23.6639C264.485 23.6159 264.549 23.504 264.549 23.328V15.936H261.117C261.021 15.936 260.925 15.9679 260.829 16.0319C260.733 16.096 260.685 16.24 260.685 16.464H260.397V12.96H260.685C260.685 13.184 260.733 13.328 260.829 13.3919C260.925 13.4559 261.021 13.488 261.117 13.488H271.197Z"
      fill={textColor}
    />
    <path
      d="M283.717 23.76C283.765 23.76 283.829 23.752 283.909 23.736C283.989 23.72 284.029 23.656 284.029 23.544C284.029 23.48 283.997 23.384 283.933 23.256C283.885 23.112 283.821 22.96 283.741 22.8C283.677 22.64 283.605 22.48 283.525 22.32C283.445 22.16 283.381 22.024 283.333 21.912H277.765C277.733 22.024 277.677 22.16 277.597 22.32C277.533 22.48 277.461 22.64 277.381 22.8C277.301 22.96 277.229 23.112 277.165 23.256C277.101 23.384 277.069 23.48 277.069 23.544C277.069 23.656 277.109 23.72 277.189 23.736C277.269 23.752 277.333 23.76 277.381 23.76V24.048H273.781V23.76C273.877 23.76 273.973 23.744 274.069 23.712C274.165 23.664 274.229 23.6 274.261 23.52C274.741 22.4 275.229 21.296 275.725 20.208C276.221 19.104 276.661 18.112 277.045 17.232C277.445 16.336 277.773 15.6 278.029 15.024C278.285 14.432 278.413 14.096 278.413 14.016C278.413 13.872 278.309 13.7919 278.101 13.7759V13.488H283.117V13.7759C282.909 13.7919 282.805 13.872 282.805 14.016C282.805 14.064 282.885 14.28 283.045 14.6639C283.221 15.0319 283.445 15.504 283.717 16.08C283.989 16.64 284.285 17.2639 284.605 17.952C284.941 18.64 285.269 19.328 285.589 20.016C285.925 20.704 286.237 21.36 286.525 21.984C286.813 22.592 287.045 23.104 287.221 23.52C287.253 23.6 287.317 23.664 287.413 23.712C287.509 23.744 287.605 23.76 287.701 23.76V24.048H283.717V23.76ZM280.501 15.9839L278.533 20.184H282.517L280.501 15.9839Z"
      fill={textColor}
    />
    <path
      d="M290.893 14.208C290.893 14.032 290.829 13.92 290.701 13.872C290.573 13.824 290.421 13.7919 290.245 13.7759V13.488H294.733V13.7759C294.557 13.7919 294.405 13.824 294.277 13.872C294.165 13.92 294.109 14.032 294.109 14.208V21.6H300.685C300.781 21.6 300.877 21.568 300.973 21.504C301.069 21.424 301.117 21.256 301.117 21H301.405V24.576H301.117C301.117 24.32 301.069 24.168 300.973 24.12C300.877 24.072 300.781 24.048 300.685 24.048H290.245V23.76C290.421 23.744 290.573 23.7119 290.701 23.6639C290.829 23.6159 290.893 23.504 290.893 23.328V14.208Z"
      fill={textColor}
    />
  </svg>
);

export default Logo;
