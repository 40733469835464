import './Dropdown.scss';
import useOnClickOutside from 'use-onclickoutside';
import { useRef, type FC, type ReactNode } from 'react';

type Props = {
  onClose: () => void;
  children: ReactNode | ReactNode[];
};

const Dropdown: FC<Props> = ({ onClose, children }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, onClose);

  return (
    <div ref={dropdownRef} className="nav-dropdown">
      {children}
    </div>
  );
};

export default Dropdown;
