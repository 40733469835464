import './List.scss';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import Document from '../../Document';
import { forwardRef, useMemo, type ForwardedRef } from 'react';
import { useDocumentContentStyle } from 'pages/procedures/hooks/useDocumentContentStyle';
import type { List as ListType, ListType as HTMLListTypes } from 'components/Document/types/Document';

const List = forwardRef<HTMLListTypes, Omit<ListType, 'contentType'>>(
  ({ style, listType, items, iconsDirectory, imagesDirectory, color, className, ...props }, ref) => {
    const styleProp = useDocumentContentStyle({ style });

    const ListItems = useMemo(
      () =>
        items.map((element) => {
          const key = uuid();
          return (
            <li className="li" key={key}>
              <Document iconsDirectory={iconsDirectory} imagesDirectory={imagesDirectory} content={element} />
            </li>
          );
        }),
      [items, iconsDirectory, imagesDirectory]
    );

    const DifferentiatedList = useMemo(() => {
      const sharedProps = {
        ref,
        style: styleProp,
        className: classNames('list', listType, color, className),

        ...props,
      };

      switch (listType) {
        case 'ul':
          return (
            <ul {...sharedProps} ref={ref as ForwardedRef<HTMLUListElement>} style={styleProp}>
              {ListItems}
            </ul>
          );
        case 'ol':
          return (
            <ol {...sharedProps} ref={ref as ForwardedRef<HTMLOListElement>} style={styleProp}>
              {ListItems}
            </ol>
          );
        default:
          throw new Error(`Unsupported list type. Expected 'ul', 'ol'. Received: ${listType}.`);
      }
    }, [listType, props, ref, styleProp, ListItems, color, className]);

    return DifferentiatedList;
  }
);

export default List;
