import { ButtonProps } from './button';
import { type ReactNode, useMemo } from 'react';
import { useHighlightedHeader } from '../hooks/useHighlightedHeader';
import SkeletonImage from './SkeletonImage';

const ButtonSection = ({
  svg,
  shape,
  header,
  preText,
  children,
  paragraphs,
  svgLoading,
  svgPosition,
  spaceBetween,
  sectionButton,
  backgroundColor,
  buttonPlacement,
  summaryPlacement,
  colorNthAmountOfEndWords,
}: {
  svg?: string;
  header: string;
  svgLoading?: boolean;
  /** CSS background-position string; see https://developer.mozilla.org/en-US/docs/Web/CSS/background-position */
  paragraphs: string[];
  svgPosition?: string;
  spaceBetween?: boolean;
  colorNthAmountOfEndWords?: number;
  children?: ReactNode | JSX.Element;
  summaryPlacement?: 'left' | 'right';
  backgroundColor?: 'background-purple';
  preText?: string | (ReactNode | ReactNode[]);
  sectionButton?: React.ReactElement<ButtonProps>;
  shape: 'full' | 'square' | 'circle' | 'flat' | null;
  buttonPlacement?: 'left' | 'right' | ['left', 'bottom'] | ['right', 'bottom'];
}) => {
  const spaceBetweenClass = spaceBetween ? 'space-between' : '';

  const PreText = useMemo(() => {
    if (!preText) return null;
    if (typeof preText === 'string') return <h2 className="section-superHeader">{preText}</h2>;
    return preText;
  }, [preText]);

  const { HighlightedHeader } = useHighlightedHeader({ text: header, colorNthAmountOfEndWords });

  const Paragraphs = useMemo(
    () =>
      paragraphs.map(
        (paragraphs, index) => (
          <h2 key={index} className="section-subHeader">
            {paragraphs}
          </h2>
        ),
        []
      ),
    [paragraphs]
  );

  const Svg = useMemo(() => {
    if (!svg) return null;
    if (svgLoading) return <SkeletonImage />;
    return (
      <div
        className={`section-image-${shape}`}
        style={
          shape === 'full'
            ? {
                backgroundImage: ` linear-gradient(89.84deg, #ffffff 7.72%, rgba(255, 255, 255, 0) 49.41%), url(${svg})`,
                backgroundPosition: svgPosition ?? 'center',
              }
            : {
                backgroundImage: `url(${svg})`,
                backgroundPosition: svgPosition ?? 'center',
              }
        }
      />
    );
  }, [svg, svgLoading, shape, svgPosition]);

  return (
    <>
      <div className={`section ${backgroundColor ?? ''}`}>
        <div className={`section-container-${shape} ${spaceBetweenClass}`}>
          {Svg}
          <div className={`section-summary ${summaryPlacement}`}>
            {PreText}
            {HighlightedHeader}
            {Paragraphs}
          </div>

          {sectionButton && (
            <span
              className={`section-button ${
                buttonPlacement instanceof Array ? buttonPlacement.join(' ') : buttonPlacement
              }`}
            >
              {sectionButton}
            </span>
          )}

          {children && <div className="section-container-children">{children}</div>}
        </div>
      </div>
    </>
  );
};

export default ButtonSection;
