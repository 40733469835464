import { useMemo, type FC } from 'react';
import './DesktopSubNavigationContent.scss';
import SubNavigation from '../SubNavigation';
import type { SubNavigation as SubNavigationType } from '../../../../routes';

type Props = {
  subNavigation: SubNavigationType;
};

const DesktopSubNavigationContent: FC<Props> = ({ subNavigation }) => {
  const subNavigationRoutesWithNoChildren = useMemo(
    () =>
      Object.keys(subNavigation).reduce((acc: SubNavigationType, key: string) => {
        if (subNavigation[key].subRoutes) return acc;
        return { ...acc, [key]: subNavigation[key] };
      }, {}),
    [subNavigation]
  );

  return (
    <div className="desktop-sub-navigation-container">
      <SubNavigation subNavigation={subNavigationRoutesWithNoChildren} />
      <SubNavigation
        collapsible={false}
        initialOpenState={true}
        subNavigation={{ cleaningsAndPrevention: subNavigation['cleaningsAndPrevention'] }}
      />
      <SubNavigation
        collapsible={false}
        initialOpenState={true}
        subNavigation={{ cosmeticDentistry: subNavigation['cosmeticDentistry'] }}
      />
      <SubNavigation
        collapsible={false}
        initialOpenState={true}
        subNavigation={{ periodontalDisease: subNavigation['periodontalDisease'] }}
      />
      <SubNavigation
        collapsible={false}
        initialOpenState={true}
        subNavigation={{ restorations: subNavigation['restorations'] }}
      />
    </div>
  );
};

export default DesktopSubNavigationContent;
