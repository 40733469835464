import './Topics.scss';
import { type FC, useMemo } from 'react';
import { Button } from 'components/button';

export type Topic = {
  name: string;
  path: string;
};

type TopicsProps = {
  topics: Topic[];
};

const Topics: FC<TopicsProps> = ({ topics }) => {
  const TopicsList = useMemo(
    () =>
      topics.map(({ name, path }, i) => (
        <li key={i}>
          <Button variant="outlined" linkTo={path}>
            {name}
          </Button>
        </li>
      )),
    [topics]
  );

  return (
    <nav className="procedure-topics">
      <div className="divider" />

      <h2>Topics</h2>
      <ul>{TopicsList}</ul>

      <div className="divider" />
    </nav>
  );
};

export default Topics;
