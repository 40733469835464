import './TextInput.scss';
import { v4 as uuid } from 'uuid';
import InputMask from 'react-input-mask';
import type { InputProps } from '../../types/InputProps';
import { useInputLabelContrastColor } from '../../hooks/useInputLabelContrastColor';
import { type FC, type ReactNode, type InputHTMLAttributes, useLayoutEffect, useMemo, useRef, useState } from 'react';

const BASE_INPUT_PADDING_HORIZONTAL = 12;

/** Props specific to text inputs (i.e., not for textareas) */
type TextInputProps = {
  /** An optional adornment to display to the left of the input; adds calculated padding to the input so that entered text is not occluded */
  inputAdornmentLeft?: ReactNode;
  /** An optional adornment to display to the left of the input; adds calculated padding to the input so that entered text is not occluded */
  inputAdornmentRight?: ReactNode;
  /** An optional mask, such as (   ) ___ - ____, to add to the input */
  mask?: string | (string | RegExp)[];
};

const TextInput: FC<InputHTMLAttributes<HTMLInputElement> & InputProps & TextInputProps> = ({
  mask,
  label,
  error,
  labelColor,
  ContainerProps,
  inputAdornmentLeft,
  inputAdornmentRight,

  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const leftAdornmentRef = useRef<HTMLDivElement>(null);
  const rightAdornmentRef = useRef<HTMLDivElement>(null);

  /** If an id is not provided, ensure that one is generated, so that the label is accessible */
  const [id] = useState(() => props.id || `input-${uuid()}`);

  /** Computed pixel values for how much padding should be added to the input to prevent occlusion by the input adornment */
  const [adornmentAwareLeftPadding, setAdornmentAwareLeftPadding] = useState(0);
  const [adornmentAwareRightPadding, setAdornmentAwareRightPadding] = useState(0);

  const computedLabelColor = useInputLabelContrastColor(labelColor);

  useLayoutEffect(() => {
    if (!leftAdornmentRef.current && !rightAdornmentRef.current) return;

    // Get the computed width of the input adornment(s)
    const leftAdornmentWidth = leftAdornmentRef.current?.getBoundingClientRect().width || 0;
    const rightAdornmentWidth = rightAdornmentRef.current?.getBoundingClientRect().width || 0;

    // Add gap to the computed width of the input adornment
    setAdornmentAwareLeftPadding(leftAdornmentWidth);
    setAdornmentAwareRightPadding(rightAdornmentWidth);
  }, [inputAdornmentLeft, inputAdornmentRight]);

  const sharedProps = useMemo(
    () => ({
      id,
      style: {
        paddingLeft: `${adornmentAwareLeftPadding + BASE_INPUT_PADDING_HORIZONTAL}px`,
        paddingRight: `${adornmentAwareRightPadding + BASE_INPUT_PADDING_HORIZONTAL}px`,
      },

      ...props,
    }),
    [id, props, adornmentAwareLeftPadding, adornmentAwareRightPadding]
  );

  return (
    <div className="text-input" {...ContainerProps}>
      <label style={{ color: computedLabelColor }} htmlFor={id}>
        {label}
      </label>

      <div className="input-container">
        {inputAdornmentLeft && (
          <div className="input-adornment left" ref={leftAdornmentRef}>
            {inputAdornmentLeft}
          </div>
        )}

        {mask ? (
          <InputMask maskChar=" " inputRef={inputRef} {...{ mask, ...sharedProps }} />
        ) : (
          <input {...sharedProps} />
        )}

        {inputAdornmentRight && (
          <div className="input-adornment right" ref={rightAdornmentRef}>
            {inputAdornmentRight}
          </div>
        )}
      </div>

      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default TextInput;
