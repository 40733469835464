import './TeamMember.scss';
import { type FC } from 'react';

type Props = {
  name: string;
  title: string;
  imageSrc: string;
};

const TeamMember: FC<Props> = ({ name, title /* imageSrc */ }) => (
  <div className="team-member">
    {/* Hide until we have headshots for each team member */}
    {/* <div className="image-container" style={{ backgroundImage: `url(${imageSrc})` }}></div> */}
    <h3>{name}</h3>
    <p>{title}</p>
  </div>
);

export default TeamMember;
