import './AppointmentForm.scss';
import docText from 'i18n/documentText.json';
import StyledIcon from 'components/styledIcon';
import CallUsButton from 'assemblies/CallUsButton';
import AppointmentForm from 'assemblies/AppointmentForm';
import ToothAppointment from 'components/icons/ToothAppointment';

/** Reskins the contact form for the Prcedures page */
const AppointmentFormContainer = () => (
  <div className="contact-form-container">
    <StyledIcon svg={<ToothAppointment />} />
    <h2>{docText.procedures.appointmentForm.heading}</h2>
    <p>{docText.procedures.appointmentForm.paragraph}</p>

    <CallUsButton />

    <AppointmentForm labelColor="light-contrast" />
  </div>
);

export default AppointmentFormContainer;
