import './OnlinePayment.scss';
import Hero from '../../components/Hero';
import StyledIcon from 'components/styledIcon';
import Chevron from 'components/icons/Chevron';
import { useMemo, type FormEvent } from 'react';
import { Button } from '../../components/button';
import LinkIcon from 'components/icons/LinkIcon';
import TextInput from '../../components/TextInput';
import docText from '../../i18n/documentText.json';
import ToothBrowser from 'components/icons/ToothBrowser';
import { useOnlinePaymentForm } from './hooks/useOnlinePaymentForm';
import dentistForm from '../../svg/dentist-making-up-a-medication-form-for-the-patient-2PMLERU@2x.svg';

const OnlinePayment = () => {
  const labelColor = 'light-contrast';

  const {
    paymentAmount,
    setPaymentAmount,

    paymentAmountBlurred,
    setPaymentAmountBlurred,

    patientFullName,
    setPatientFullName,

    patientFullNameBlurred,
    setPatientFullNameBlurred,

    cardholderFirstName,
    setCardholderFirstName,

    cardholderFirstNameBlurred,
    setCardholderFirstNameBlurred,

    cardholderLastName,
    setCardholderLastName,

    cardholderLastNameBlurred,
    setCardholderLastNameBlurred,

    addressLine1,
    setAddressLine1,

    addressLine1Blurred,
    setAddressLine1Blurred,

    city,
    setCity,

    cityBlurred,
    setCityBlurred,

    stateOptions,
    state,
    setState,

    stateBlurred,
    setStateBlurred,

    postalCode,
    setPostalCode,

    postalCodeBlurred,
    setPostalCodeBlurred,

    errors,
    loading,
    errorsExist,
    handleSubmit,
    didAttemptSubmit,
  } = useOnlinePaymentForm();

  const StateOptions = useMemo(
    () =>
      stateOptions.map((state: string) => (
        <option key={state} value={state}>
          {state}
        </option>
      )),
    [stateOptions]
  );

  return (
    <>
      <Hero
        svg={dentistForm}
        focalPosition="left"
        h1={docText.onlinePayment.hero.h1}
        paragraphs={docText.onlinePayment.hero.h2}
        preText={docText.onlinePayment.hero.superHeader}
        sectionButton={<></>}
      />
      <main className="online-payment">
        <section className="online-payment-form-container">
          <StyledIcon svg={<ToothBrowser />} />
          <h2>{docText.onlinePayment.form.heading}</h2>
          <p>
            <em>{docText.onlinePayment.form.requiredInstruction}</em>
          </p>
          <iframe className='iframe-form' loading={"lazy"} width="100%" height="1000px" src="https://bestcardteam.com/Payments/?Profile=Altitudepay"></iframe>
          {/* TODO:p2 We can probably get rid of the entire form because the API that we are using is being updated however this is a good practice And to be used as a reference for dry*/}
          {/* <form
            className="online-payment-form"
            onSubmit={(e: FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <TextInput
              type="number"
              value={paymentAmount}
              labelColor={labelColor}
              ContainerProps={{
                style: { gridArea: 'paymentAmount' },
              }}
              id="online-payment-form-payment-amount"
              onBlur={() => setPaymentAmountBlurred(true)}
              onChange={(e) => setPaymentAmount(e.target.value)}
              onKeyDown={(e) => {
                // Prevent the user from entering the letter 'e' or signed numbers in the input (scientific notation is accepted in number inputs)
                if (e.key === 'e' || e.key === '-' || e.key === '+') e.preventDefault();
              }}
              label={docText.onlinePayment.form.paymentAmount.label}
              inputAdornmentLeft={<div className="dollar-adornment">$</div>}
              error={paymentAmountBlurred || didAttemptSubmit ? errors.paymentAmount : undefined}
            />

            <TextInput
              value={patientFullName}
              labelColor={labelColor}
              ContainerProps={{
                style: { gridArea: 'name' },
              }}
              id="appointment-form-full-name-input"
              onBlur={() => setPatientFullNameBlurred(true)}
              onChange={(e) => setPatientFullName(e.target.value)}
              label={docText.onlinePayment.form.patientFullName.label}
              error={patientFullNameBlurred || didAttemptSubmit ? errors.patientFullName : undefined}
            />

            <div style={{ gridArea: 'cardholder' }}>
              <fieldset>
                <legend>
                  <h3>{docText.onlinePayment.form.billingAddressFieldset.label}</h3>
                </legend>

                <div className="cardholder-subgrid">
                  <TextInput
                    labelColor={labelColor}
                    value={cardholderFirstName}
                    ContainerProps={{
                      style: { gridArea: 'first' },
                    }}
                    onBlur={() => setCardholderFirstNameBlurred(true)}
                    id="online-payment-form-cardholder-first-name-input"
                    onChange={(e) => setCardholderFirstName(e.target.value)}
                    label={docText.onlinePayment.form.cardHolderFirstName.label}
                    error={cardholderFirstNameBlurred || didAttemptSubmit ? errors.cardholderFirstName : undefined}
                  />

                  <TextInput
                    labelColor={labelColor}
                    value={cardholderLastName}
                    ContainerProps={{
                      style: { gridArea: 'last' },
                    }}
                    onBlur={() => setCardholderLastNameBlurred(true)}
                    id="online-payment-form-cardholder-last-name-input"
                    onChange={(e) => setCardholderLastName(e.target.value)}
                    label={docText.onlinePayment.form.cardHolderLastName.label}
                    error={cardholderLastNameBlurred || didAttemptSubmit ? errors.cardholderLastName : undefined}
                  />

                  <TextInput
                    value={addressLine1}
                    labelColor={labelColor}
                    ContainerProps={{
                      style: { gridArea: 'address' },
                    }}
                    onBlur={() => setAddressLine1Blurred(true)}
                    label={docText.onlinePayment.form.billingAddressFieldset.fields.address.label}
                    id="online-payment-form-cardholder-address-input"
                    onChange={(e) => setAddressLine1(e.target.value)}
                    error={addressLine1Blurred || didAttemptSubmit ? errors.addressLine1 : undefined}
                  />

                  <TextInput
                    value={city}
                    labelColor={labelColor}
                    ContainerProps={{
                      style: { gridArea: 'city' },
                    }}
                    onBlur={() => setCityBlurred(true)}
                    onChange={(e) => setCity(e.target.value)}
                    label={docText.onlinePayment.form.billingAddressFieldset.fields.city.label}
                    id="online-payment-form-cardholder-city-input"
                    error={cityBlurred || didAttemptSubmit ? errors.city : undefined}
                  />

                  <div style={{ gridArea: 'state' }} className="state-select-container">
                    <label className="state-select-label" htmlFor="online-payment-form-cardholder-state-input">
                      {docText.onlinePayment.form.billingAddressFieldset.fields.state.label}
                    </label>
                    <select
                      value={state}
                      className="state-select"
                      onBlur={() => setStateBlurred(true)}
                      onChange={(e) => setState(e.target.value)}
                      id="online-payment-form-cardholder-state-input"
                    >
                      {StateOptions}
                    </select>

                    <div className="select-chevron">
                      <Chevron color="#667085" />
                    </div>

                    {stateBlurred || didAttemptSubmit ? <p className="state-select-error">{errors.state}</p> : null}
                  </div>

                  <TextInput
                    type="number"
                    value={postalCode}
                    labelColor={labelColor}
                    ContainerProps={{
                      style: { gridArea: 'postalCode' },
                    }}
                    onBlur={() => setPostalCodeBlurred(true)}
                    onChange={(e) => setPostalCode(e.target.value)}
                    onKeyDown={(e) => {
                      // Prevent the user from entering the letter 'e' or signed numbers in the input (scientific notation is accepted in number inputs)
                      if (e.key === 'e' || e.key === '-' || e.key === '+') e.preventDefault();
                    }}
                    id="online-payment-form-cardholder-postal-code-input"
                    error={postalCodeBlurred || didAttemptSubmit ? errors.postalCode : undefined}
                    label={docText.onlinePayment.form.billingAddressFieldset.fields.postalCode.label}
                  />
                </div>
              </fieldset>
            </div>

            <div style={{ gridArea: 'submit' }} className="submit-button-container">
              <Button adornmentRight={<LinkIcon color="#ffffff" />} disabled={errorsExist || loading} type="submit">
                {docText.onlinePayment.form.buttonText}
              </Button>
            </div>
          </form> */}
        </section>
      </main>
    </>
  );
};

export default OnlinePayment;
