import { useCallback, useMemo, useState } from 'react';

export type QuestionTypes = 'boolean';
/** | union additional question types (i.e., 'multiple choice') */

export type Question = {
  question: string;
  questionType: QuestionTypes;
};

export type Answer = Question & {
  answer: null | boolean; // null if yet to be answered
};

export type UseQuizHookProps = { questions: Question[]; onQuizComplete: (answers: Answer[]) => void };

export const useQuiz = ({ questions, onQuizComplete }: UseQuizHookProps) => {
  /**
   * State
   */

  const [complete, setComplete] = useState<boolean>(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [answers, setAnswers] = useState<Answer[]>(questions.map((question) => ({ ...question, answer: null })));

  /**
   * Derived State
   */

  const currentQuestion = useMemo(() => answers[currentQuestionIndex], [currentQuestionIndex, answers]);

  /**
   * State-dependent Callbacks
   */

  const handleAnswer = useCallback(
    (answer: boolean) => {
      setAnswers((previousState) => {
        /** Immutably replace answer object reflecting user-specified answer */
        const newAnswers = [...previousState];
        newAnswers[currentQuestionIndex] = { ...newAnswers[currentQuestionIndex], answer };
        return newAnswers;
      });
    },
    [currentQuestionIndex]
  );

  const decrementQuestion = useCallback(() => {
    /** Prevent out-of-bounds errors */
    if (currentQuestionIndex === 0) return;

    /** Don't decrement the current question if the quiz is complete, because it's already at the last index */
    if (complete) return setComplete(false);

    setCurrentQuestionIndex((previousState) => previousState - 1);
  }, [currentQuestionIndex, complete]);

  const incrementQuestion = useCallback(() => {
    /** Prevent out-of-bounds errors */
    if (currentQuestionIndex === questions.length - 1) {
      setComplete(true);
      onQuizComplete(answers);

      return;
    }

    return setCurrentQuestionIndex((previousState) => previousState + 1);
  }, [currentQuestionIndex, questions.length, answers, onQuizComplete]);

  return {
    answers,
    complete,
    handleAnswer,
    currentQuestion,
    decrementQuestion,
    incrementQuestion,
    currentQuestionIndex,
  };
};
