import './Quiz.scss';
import { Button } from '../../../../components/button';
import { useMemo, type FC, type ReactNode } from 'react';
import ArrowIcon from '../../../../components/icons/ArrowRight';
import { type Question, type Answer, useQuiz } from './hooks/useQuiz';
import BooleanQuizQuestion from '../../components/BooleanQuizQuestion';
import SteppedLinearProgress from '../../../../components/SteppedLinearProgress';

export type QuizProps = {
  /** An array of questions for the Quiz to ask */
  questions: Question[];
  /** The content to display when the quiz is complete */
  CompleteStateContent: ReactNode | ReactNode[];
  /** A callback to pass the answers from the completed quiz to the consuming component */
  onQuizComplete: (answers: Answer[]) => void;
};

const Quiz: FC<QuizProps> = ({ questions, CompleteStateContent, onQuizComplete }) => {
  const { complete, handleAnswer, currentQuestion, decrementQuestion, incrementQuestion, currentQuestionIndex } =
    useQuiz({ questions, onQuizComplete });

  /**
   * Derived UI-specific State
   */

  const previousButtonDisabled = useMemo(() => currentQuestionIndex === 0, [currentQuestionIndex]);

  /** The user has not yet made a selection for the current question */
  const nextButtonDisabled = useMemo(
    () => currentQuestion.answer === null || complete,
    [currentQuestion.answer, complete]
  );

  /**
   * Differentiate which type of question to display (if we build out other
   * types of questions for DRY, this is where we would add new question types.)
   */
  const Question = useMemo(() => {
    switch (currentQuestion.questionType) {
      case 'boolean':
        return <BooleanQuizQuestion onAnswer={handleAnswer} questionObject={currentQuestion} />;
      /* ...Additional question types as needed (Altitude only needs yes/no questions) */

      default:
        throw new Error(`Unhandled question type ${currentQuestion.questionType}`);
    }
  }, [currentQuestion, handleAnswer]);

  /** Differentiate whether to display a question vs. complete state */
  const QuizContent = useMemo(
    () => (complete ? CompleteStateContent : Question),
    [CompleteStateContent, complete, Question]
  );

  return (
    <section className="quiz-container">
      <div className="quiz">
        <SteppedLinearProgress
          numberOfSteps={questions.length}
          currentStep={complete ? currentQuestionIndex + 1 : currentQuestionIndex}
        />

        {QuizContent}

        <div className="quiz-navigation">
          <Button
            variant="outlined"
            onClick={decrementQuestion}
            className="quiz-previous-button"
            disabled={previousButtonDisabled}
            adornmentLeft={<ArrowIcon color="#475467" />}
          >
            Previous
          </Button>

          <Button
            variant="solid"
            onClick={incrementQuestion}
            className="quiz-next-button"
            disabled={nextButtonDisabled}
            adornmentRight={<ArrowIcon color="#ffffff" />}
          >
            Next
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Quiz;
