/** TODO: Replace with Dry icon  */

import type { IconProps } from './IconProps';

const ThumbUpIcon = ({ color = '#344054' }: IconProps) => (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none">
    <path
      d="M5.33268 18.3333V9.16663M1.16602 10.8333V16.6666C1.16602 17.5871 1.91221 18.3333 2.83268 18.3333H14.0212C15.2551 18.3333 16.3045 17.433 16.4921 16.2134L17.3896 10.3801C17.6226 8.86571 16.4509 7.49996 14.9187 7.49996H11.9993C11.5391 7.49996 11.166 7.12686 11.166 6.66663V3.72149C11.166 2.58662 10.246 1.66663 9.11115 1.66663C8.84046 1.66663 8.59517 1.82604 8.48523 2.0734L5.55263 8.67174C5.41888 8.97268 5.12045 9.16663 4.79112 9.16663H2.83268C1.91221 9.16663 1.16602 9.91282 1.16602 10.8333Z"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ThumbUpIcon;
