import { useEffect, useMemo, useState } from 'react';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const useIntlPhoneNumber = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [region, setRegion] = useState('US');

  /** Generate the phone number prefix or country code; '+1' in the case of the United States */
  const prefix = useMemo(() => `+${phoneUtil.getCountryCodeForRegion(region)}`, [region]);

  /** Generate the appropriate input mask based on how selected country writes phone numbers, for the sake of user experience; (999) 999-9999 in the case of the United States */
  const mask = useMemo(
    () => phoneUtil.format(phoneUtil.getExampleNumber(region), PhoneNumberFormat.NATIONAL).replace(/\d/g, '9'),
    [region]
  );

  /** The internationally-formatted phone number for storage and validation purposes; null if unable to format because of incomplete / invalid entry */
  const formattedPhone = useMemo(() => {
    try {
      return phoneUtil.format(phoneUtil.parse(`${prefix} ${phoneNumber}`, region), PhoneNumberFormat.INTERNATIONAL);
    } catch (error) {
      return null;
    }
  }, [phoneNumber, prefix, region]);

  /**
   * Clear the phone number input when the region changes
   * (different regions have different mask formats and
   * digit lengths).
   */
  useEffect(() => {
    setPhoneNumber('');
  }, [region]);

  return {
    mask,
    prefix,

    region,
    setRegion,

    formattedPhone,

    phoneNumber,
    setPhoneNumber,
  };
};
