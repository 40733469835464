import './smileAnalysis.scss';
import Quiz from './components/Quiz/Quiz';
import { useMemo, useState } from 'react';
import docText from '../../i18n/documentText.json';
import Testimonials from '../../assemblies/Testimonials';
import ButtonSection from '../../components/buttonSection';
import AppointmentForm from '../../assemblies/AppointmentForm';
import type { Question, Answer } from './components/Quiz/hooks/useQuiz';

const questions = docText.smileAnalysis.questions as Question[];

const SmileAnalysis = () => {
  const [answers, setAnswers] = useState<Answer[] | null>(null);

  const didAnswerYesToAnyQuestion: boolean | null = useMemo(() => {
    if (!answers) return null;
    return answers.some(({ answer }) => answer);
  }, [answers]);

  const CompleteStateContent = useMemo(() => {
    if (didAnswerYesToAnyQuestion === null) return null;
    return (
      <div className="quiz-complete">
        <h1 className="quiz-complete-heading">
          {didAnswerYesToAnyQuestion
            ? docText.smileAnalysis.completedState.didAnswerYesToAnyQuestion.h1
            : docText.smileAnalysis.completedState.answeredNoToAllQuestions.h1}
        </h1>
        <p className="quiz-complete-paragraph">
          {didAnswerYesToAnyQuestion
            ? docText.smileAnalysis.completedState.didAnswerYesToAnyQuestion.p
            : docText.smileAnalysis.completedState.answeredNoToAllQuestions.p}
        </p>

        <AppointmentForm smileAnalysisAnswers={answers} labelColor="light-contrast" />
      </div>
    );
  }, [didAnswerYesToAnyQuestion, answers]);

  return (
    <main className="smile-analysis">
      <ButtonSection shape={null} header={docText.smileAnalysis.hero.h1} paragraphs={docText.smileAnalysis.hero.h2}>
        <Quiz
          questions={questions}
          CompleteStateContent={CompleteStateContent}
          onQuizComplete={(answers: Answer[]) => setAnswers(answers)}
        />
      </ButtonSection>

      <Testimonials testimonials={docText.testimonials.content} />
    </main>
  );
};
export default SmileAnalysis;
