import './MobileNavContent.scss';
import Accordion from '../Accordion';
import MobileNavCTA from '../MobileNavCTA';
import SubNavigationComponent from '../SubNavigation';
import { useLayout } from '../../../../context/Layout/useLayout';
import { navRoutes, type SubNavigation } from '../../../../routes';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';

const NAV_BAR_HEIGHT = 68;

const MobileNavContent = () => {
  const ctaRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const { windowInnerHeight } = useLayout();

  const [minListHeight, setMinListHeight] = useState('100%');

  /** Recalculate the minListHeight on accordion state change */
  const onOpenStateChange = useCallback(() => {
    setMinListHeight(
      `calc(${windowInnerHeight}px - ${NAV_BAR_HEIGHT}px - ${ctaRef.current?.getBoundingClientRect().height ?? 0}px)`
    );
  }, [windowInnerHeight]);

  /** Ensure the CTA is always at the bottom of the screen, even when the content doesn't fill it up.  */
  useLayoutEffect(() => {
    if (!ctaRef.current) return;
    onOpenStateChange();
  }, [ctaRef, windowInnerHeight, onOpenStateChange]);

  return (
    <>
      <ul
        ref={listRef}
        style={{
          minHeight: minListHeight,
        }}
        className="mobile-nav-content"
      >
        {Object.keys(navRoutes).map((key: string) =>
          navRoutes[key].visibleInNav ? (
            <li className="parent-list-item" key={key}>
              {navRoutes[key].subNavigation ? (
                <Accordion
                  key={key}
                  onOpenStateChange={onOpenStateChange}
                  parentContent={
                    <>
                      {navRoutes[key].path ? (
                        <a href={navRoutes[key].path}>{navRoutes[key].name}</a>
                      ) : (
                        <p>{navRoutes[key].name}</p>
                      )}
                    </>
                  }
                >
                  <SubNavigationComponent
                    onOpenStateChange={onOpenStateChange}
                    subNavigation={navRoutes[key].subNavigation as SubNavigation}
                  />
                </Accordion>
              ) : (
                <a href={navRoutes[key].path ?? '/'}>{navRoutes[key].name}</a>
              )}
            </li>
          ) : null
        )}
      </ul>

      <MobileNavCTA ref={ctaRef} />
    </>
  );
};

export default MobileNavContent;
