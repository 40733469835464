import './LineBreak.scss';
import { forwardRef } from 'react';
import classNames from 'classnames';
import { LineBreak as LineBreakType } from '../../types/Document';
import { useDocumentContentStyle } from 'pages/procedures/hooks/useDocumentContentStyle';

const LineBreak = forwardRef<HTMLBRElement, Omit<LineBreakType, 'contentType'>>(({ style, className }, ref) => {
  const styleProp = useDocumentContentStyle({ style });

  return <br ref={ref} className={classNames('line-break', className)} style={styleProp} />;
});

export default LineBreak;
