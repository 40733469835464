import './index.scss';
import Home from './pages/home';
// import Shop from './pages/shop';
import About from './pages/about';
import PageNotFound from './pages/404';
import type { ReactNode } from 'react';
// import Password from './pages/password';
import ContactUs from './pages/contactUs';
import docText from './i18n/documentText.json';
import StyledIcon from './components/styledIcon';
import OnlinePayment from './pages/onlinePayment';
import SmileAnalysis from './pages/smileAnalysis';
import ToothLove from './components/icons/ToothLove';
import type { PathRouteProps } from 'react-router-dom';
import ToothShine from './components/icons/ToothShine';
import PatientForm from './pages/patientForm/patientForm';
import ToothMedical from './components/icons/ToothMedical';
import ToothProtection from './components/icons/ToothProtection';
import ToothAppointment from './components/icons/ToothAppointment';
import CovidVisitationProtocol from 'pages/covidVisitationProtocol';
import ToothReconstruction from './components/icons/ToothReconstruction';
import { ProcedureCategoryPage, ProcedurePage } from './pages/procedures';
import type { IndexableProcedure } from './components/Document/types/Procedure';
import type { Category, IndexableCategory } from './components/Document/types/Category';
import GetReadyForYourAppointment from 'pages/about/assemblies/GetReadyForYourAppointment';

export type SubRoute = {
  [key: string]: PathRouteProps & { labelText: string; path: string };
};

export type SubNavigation = {
  [key: string]: PathRouteProps & {
    name: string;
    icon: ReactNode;
    subRoutes?: SubRoute;
  };
};

export type NavRoute = {
  [key: string]: PathRouteProps & { name: string; visibleInNav: boolean; subNavigation?: SubNavigation };
};

export const navRoutes: NavRoute = {
  home: {
    path: '/',
    element: <Home />,
    visibleInNav: true,
    name: docText.nav.home.labelText,
  },
  about: {
    path: '/about',
    element: <About />,
    visibleInNav: true,
    name: docText.nav.about.labelText,
  },
  // TODO: Investigate if there's a way to condense this into a loop
  procedures: {
    visibleInNav: true,
    path: '/procedures/child-checkups',
    element: (
      <ProcedureCategoryPage
        allCategories={docText.procedures.categories as IndexableCategory}
        category={docText.procedures.categories['/procedures/child-checkups'] as Category}
        infoBoxTopic={docText.procedures.categories['/procedures/child-checkups'].infoBoxTopic}
      />
    ),
    name: docText.nav.procedures.labelText,
    subNavigation: {
      childCheckups: {
        path: '/procedures/child-checkups',
        icon: <StyledIcon innerSize={40} outerSize={56} svg={<ToothAppointment />} />,
        name: docText.nav.procedures.subNavigation.childCheckups.labelText,
        element: (
          <ProcedureCategoryPage
            allCategories={docText.procedures.categories as IndexableCategory}
            category={docText.procedures.categories['/procedures/child-checkups'] as Category}
            infoBoxTopic={docText.procedures.categories['/procedures/child-checkups'].infoBoxTopic}
          />
        ),
      },
      mouthGuards: {
        path: '/procedures/mouth-guards',
        icon: <StyledIcon innerSize={40} outerSize={56} svg={<ToothProtection />} />,
        name: docText.nav.procedures.subNavigation.mouthGuards.labelText,
        element: (
          <ProcedureCategoryPage
            allCategories={docText.procedures.categories as IndexableCategory}
            category={docText.procedures.categories['/procedures/mouth-guards'] as Category}
            infoBoxTopic={docText.procedures.categories['/procedures/mouth-guards'].infoBoxTopic}
          />
        ),
      },
      sealingOutToothDecay: {
        icon: <StyledIcon innerSize={40} outerSize={56} svg={<ToothLove />} />,
        path: '/procedures/sealing-out-tooth-decay',
        name: docText.nav.procedures.subNavigation.sealingOutToothDecay.labelText,
        element: (
          <ProcedureCategoryPage
            allCategories={docText.procedures.categories as IndexableCategory}
            category={docText.procedures.categories['/procedures/sealing-out-tooth-decay'] as Category}
            infoBoxTopic={docText.procedures.categories['/procedures/sealing-out-tooth-decay'].infoBoxTopic}
          />
        ),
      },
      cleaningsAndPrevention: {
        icon: <StyledIcon innerSize={40} outerSize={56} svg={<ToothShine />} />,
        path: '/procedures/cleanings-and-prevention',
        name: docText.nav.procedures.subNavigation.cleaningsAndPrevention.labelText,
        element: (
          <ProcedureCategoryPage
            allCategories={docText.procedures.categories as IndexableCategory}
            category={docText.procedures.categories['/procedures/cleanings-and-prevention'] as Category}
            infoBoxTopic={docText.procedures.categories['/procedures/cleanings-and-prevention'].infoBoxTopic}
          />
        ),
        subRoutes: Object.keys(docText.nav.procedures.subNavigation.cleaningsAndPrevention.subRoutes).reduce(
          (acc: SubRoute, key: string) => ({
            ...acc,
            [key]: {
              ...(docText.nav.procedures.subNavigation.cleaningsAndPrevention.subRoutes as SubRoute)[key],
              element: (
                <ProcedurePage
                  procedure={
                    (
                      docText.procedures.categories['/procedures/cleanings-and-prevention']
                        .procedures as IndexableProcedure
                    )[(docText.nav.procedures.subNavigation.cleaningsAndPrevention.subRoutes as SubRoute)[key].path]
                  }
                  allProcedures={
                    (docText.procedures.categories as IndexableCategory)['/procedures/cleanings-and-prevention']
                      .procedures as IndexableProcedure
                  }
                />
              ),
            },
          }),
          {}
        ),
      },
      cosmeticDentistry: {
        icon: <StyledIcon innerSize={40} outerSize={56} svg={<ToothShine />} />,
        path: '/procedures/cosmetic-dentistry',
        name: docText.nav.procedures.subNavigation.cosmeticDentistry.labelText,
        element: (
          <ProcedureCategoryPage
            allCategories={docText.procedures.categories as IndexableCategory}
            category={docText.procedures.categories['/procedures/cosmetic-dentistry'] as Category}
            infoBoxTopic={docText.procedures.categories['/procedures/cosmetic-dentistry'].infoBoxTopic}
          />
        ),
        subRoutes: Object.keys(docText.nav.procedures.subNavigation.cosmeticDentistry.subRoutes).reduce(
          (acc: SubRoute, key: string) => ({
            ...acc,
            [key]: {
              ...(docText.nav.procedures.subNavigation.cosmeticDentistry.subRoutes as SubRoute)[key],
              element: (
                <ProcedurePage
                  procedure={
                    (docText.procedures.categories['/procedures/cosmetic-dentistry'].procedures as IndexableProcedure)[
                      (docText.nav.procedures.subNavigation.cosmeticDentistry.subRoutes as SubRoute)[key].path
                    ]
                  }
                  allProcedures={
                    (docText.procedures.categories as IndexableCategory)['/procedures/cosmetic-dentistry']
                      .procedures as IndexableProcedure
                  }
                />
              ),
            },
          }),
          {}
        ),
      },
      periodontalDisease: {
        icon: <StyledIcon innerSize={40} outerSize={56} svg={<ToothMedical />} />,
        path: '/procedures/periodontal-disease',
        name: docText.nav.procedures.subNavigation.periodontalDisease.labelText,
        element: (
          <ProcedureCategoryPage
            allCategories={docText.procedures.categories as IndexableCategory}
            category={docText.procedures.categories['/procedures/periodontal-disease'] as Category}
            infoBoxTopic={docText.procedures.categories['/procedures/periodontal-disease'].infoBoxTopic}
          />
        ),
        subRoutes: Object.keys(docText.nav.procedures.subNavigation.periodontalDisease.subRoutes).reduce(
          (acc: SubRoute, key: string) => ({
            ...acc,
            [key]: {
              ...(docText.nav.procedures.subNavigation.periodontalDisease.subRoutes as SubRoute)[key],
              element: (
                <ProcedurePage
                  procedure={
                    (docText.procedures.categories['/procedures/periodontal-disease'].procedures as IndexableProcedure)[
                      (docText.nav.procedures.subNavigation.periodontalDisease.subRoutes as SubRoute)[key].path
                    ]
                  }
                  allProcedures={
                    (docText.procedures.categories as IndexableCategory)['/procedures/periodontal-disease']
                      .procedures as IndexableProcedure
                  }
                />
              ),
            },
          }),
          {}
        ),
      },
      restorations: {
        icon: <StyledIcon innerSize={40} outerSize={56} svg={<ToothReconstruction />} />,
        path: '/procedures/restorations',
        name: docText.nav.procedures.subNavigation.restorations.labelText,
        element: (
          <ProcedureCategoryPage
            allCategories={docText.procedures.categories as IndexableCategory}
            category={docText.procedures.categories['/procedures/restorations'] as Category}
            infoBoxTopic={docText.procedures.categories['/procedures/restorations'].infoBoxTopic}
          />
        ),
        subRoutes: Object.keys(docText.nav.procedures.subNavigation.restorations.subRoutes).reduce(
          (acc: SubRoute, key: string) => ({
            ...acc,
            [key]: {
              ...(docText.nav.procedures.subNavigation.restorations.subRoutes as SubRoute)[key],
              element: (
                <ProcedurePage
                  procedure={
                    (docText.procedures.categories['/procedures/restorations'].procedures as IndexableProcedure)[
                      (docText.nav.procedures.subNavigation.restorations.subRoutes as SubRoute)[key].path
                    ]
                  }
                  allProcedures={
                    (docText.procedures.categories as IndexableCategory)['/procedures/restorations']
                      .procedures as IndexableProcedure
                  }
                />
              ),
            },
          }),
          {}
        ),
      },
    },
  },
  smileAnalysis: {
    path: '/smile-analysis',
    element: <SmileAnalysis />,
    name: 'Smile Analysis',
    visibleInNav: true,
  },
  forms: {
    path: '/forms',
    element: <PatientForm />,
    name: 'Forms',
    visibleInNav: true,
  },
  /* Hide for MVP */
  // onlinePayment: {
  //   path: '/online-payment',
  //   element: <div>Online Payment</div>,
  //   name: 'Online Payment',
  //   visibleInNav: true,
  // },
  contactUs: {
    path: '/contact-us',
    element: <ContactUs />,
    name: 'Contact Us',
    visibleInNav: true,
  },
  onlinePayment: {
    path: '/online-payments',
    element: <OnlinePayment />,
    name: 'Online Payment',
    visibleInNav: true,
  },
  covidVisitationProtocol: {
    path: '/covid-visitation-protocol',
    element: <CovidVisitationProtocol />,
    name: 'COVID-19 Visitation Protocol',
    visibleInNav: false,
  },
  /* Hide for MVP */
  // shop: {
  //   path: '/shop',
  //   element: <Shop />,
  //   name: 'Shop',
  //   visibleInNav: true,
  // },
    shop: {
    path: '/book-online',
    element: <GetReadyForYourAppointment />,
    name: 'Book Online',
    visibleInNav: false,
  },
  '': {
    name: 'Page Not Found',
    path: '/*',
    element: <PageNotFound />,
    visibleInNav: false,
  },

  /* Hide for MVP */
  // ' ': {
  //   name: 'Reset Password',
  //   path: '/reset-password',
  //   element: <Password />,
  //   visibleInNav: false,
  // },
};

const flattenRoutes = (routes: NavRoute): PathRouteProps[] => {
  const flattenedRoutes: PathRouteProps[] = [];
  Object.values(routes).forEach((route) => {
    flattenedRoutes.push(route);

    if (!route.subNavigation) return;

    flattenedRoutes.push(
      ...Object.values(route.subNavigation).map(({ name, path, element, subRoutes }) => {
        /** Push procedure routes */
        if (subRoutes)
          flattenedRoutes.push(
            ...Object.values(subRoutes).map(({ labelText, path, element }) => ({
              name: labelText,
              path,
              element,
              visibleInNav: true,
            }))
          );

        /** Push category routes */
        return {
          name,
          path,
          element,
          visibleInNav: true,
        };
      })
    );
  });

  return flattenedRoutes;
};

export const flattenedRoutes = flattenRoutes(navRoutes);
