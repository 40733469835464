import './AppointmentForm.scss';
import { Button } from '../../components/button';
// import Checkbox from '../../components/Checkbox';
import Textarea from '../../components/Textarea';
import TextInput from '../../components/TextInput';
import docText from '../../i18n/documentText.json';
import type { InputProps } from '../../types/InputProps';
import type {
  FormEvent,
  // ChangeEvent,
  // MouseEvent
} from 'react';
import { useAppointmentForm } from './hooks/useAppointmentForm';
import CountryCodeAdornment from '../../pages/about/components/CountryCodeAdornment';
import { type Answer } from '../../pages/smileAnalysis/components/Quiz/hooks/useQuiz';

type Props = {
  /** Foreground color of the form labels; adjustable due to the form needing to be present on multiple background colors */
  labelColor?: InputProps['labelColor'];
  /** Pass Smile Analysis answers, if the form is completed in that context */
  smileAnalysisAnswers?: Answer[] | null;
};

const AppointmentForm = ({ labelColor = 'dark-contrast', smileAnalysisAnswers }: Props) => {
  const {
    fullName,
    fullNameBlurred,

    setFullName,
    setFullNameBlurred,

    mask,
    prefix,
    region,
    setRegion,

    phoneNumber,
    phoneNumberBlurred,

    setPhoneNumber,
    setPhoneNumberBlurred,

    email,
    emailBlurred,

    setEmail,
    setEmailBlurred,

    message,
    messageBlurred,

    setMessage,
    setMessageBlurred,

    // HIDE FOR MVP
    // agreedToPrivacyPolicy,
    // setAgreedToPrivacyPolicy,

    errors,
    errorsExist,
    handleSubmit,
    didAttemptSubmit,

    loading,
    submitted,
  } = useAppointmentForm({
    smileAnalysisAnswers,
  });

  if (submitted) {
    return (
      <div className="success-state">
        <h1>{docText.about.section2.afterSubmit.h1}</h1>
        <p>{docText.about.section2.afterSubmit.p}</p>
      </div>
    );
  }

  return (
    <form
      className="appointment-form"
      onSubmit={(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleSubmit(e);
      }}
    >
      <TextInput
        value={fullName}
        labelColor={labelColor}
        ContainerProps={{
          style: { gridArea: 'name' },
        }}
        id="appointment-form-full-name-input"
        onBlur={() => setFullNameBlurred(true)}
        onChange={(e) => setFullName(e.target.value)}
        label={docText.about.section2.form.nameInput.label}
        error={fullNameBlurred || didAttemptSubmit ? errors.fullName : undefined}
      />

      <TextInput
        mask={mask}
        value={phoneNumber}
        labelColor={labelColor}
        ContainerProps={{
          style: { gridArea: 'phone' },
        }}
        id="appointment-form-phone-number-input"
        onBlur={() => setPhoneNumberBlurred(true)}
        onChange={(e) => setPhoneNumber(e.target.value)}
        label={docText.about.section2.form.phoneInput.label}
        error={phoneNumberBlurred || didAttemptSubmit ? errors.phoneNumber : undefined}
        inputAdornmentLeft={
          <CountryCodeAdornment value={region} prefix={prefix} onChange={(e) => setRegion(e.target.value)} />
        }
      />

      <TextInput
        value={email}
        labelColor={labelColor}
        ContainerProps={{
          style: { gridArea: 'email' },
        }}
        id="appointment-form-email-input"
        onBlur={() => setEmailBlurred(true)}
        onChange={(e) => setEmail(e.target.value)}
        label={docText.about.section2.form.emailInput.label}
        error={emailBlurred || didAttemptSubmit ? errors.email : undefined}
      />

      <Textarea
        value={message}
        ContainerProps={{
          style: {
            gridArea: 'message',
          },
        }}
        labelColor={labelColor}
        id="appointment-form-message-textarea"
        onBlur={() => setMessageBlurred(true)}
        onChange={(e) => setMessage(e.target.value)}
        label={docText.about.section2.form.textareaInput.label}
        placeholder={docText.about.section2.form.textareaInput.placeholder}
        error={messageBlurred || didAttemptSubmit ? errors.message : undefined}
      />

      {/* Hide for MVP */}
      {/* <div style={{ gridArea: 'privacyPolicy' }} className="privacy-policy-container">
        <Checkbox
          color={labelColor}
          LabelProps={{
            labelColor,
          }}
          LabelContent={
            <span>
              {docText.about.section2.form.privacyPolicyAgreement}{' '}
              <a
                onMouseDown={(e: MouseEvent<HTMLAnchorElement>) => e.stopPropagation()}
                className="privacy-policy-link"
                href="/privacy-policy"
              >
                {docText.about.section2.form.privacyPolicyLinkText}
              </a>
            </span>
          }
          checked={agreedToPrivacyPolicy}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setAgreedToPrivacyPolicy(e.target.checked)}
        />
      </div> */}

      <div style={{ gridArea: 'submit' }} className="submit-button-container">
        <Button disabled={errorsExist || loading} type="submit">
          Send message
        </Button>
      </div>
    </form>
  );
};

export default AppointmentForm;
