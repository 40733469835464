import './ContactInfo.scss';
import type { ReactNode } from 'react';

type Props = {
  /** The icon to pass to the ContactInfo grouping */
  Icon: ReactNode;
  /** The title of the piece of information to be displayed */
  label: string;
  /** An array of strings, each of which to be placed on its own line  */
  value: string[];
};

const ContactInfo = ({ label, value, Icon }: Props) => (
  <section className="contact-info">
    {Icon}

    <h3 className="contact-info-label">{label}</h3>

    {value.map((line, index) => (
      <p className="contact-info-value" key={index}>
        {line}
      </p>
    ))}
  </section>
);

export default ContactInfo;
