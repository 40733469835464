import './IconSection.scss';
import { type FC, type ReactNode } from 'react';

type IconSectionProps = {
  children: ReactNode | ReactNode[];
  HeaderContent: ReactNode | ReactNode[];
};

const IconSection: FC<IconSectionProps> = ({ HeaderContent, children }) => {
  return (
    <section className="icon-section">
      <div className="container">
        {HeaderContent}
        <div className="icon-grid">{children}</div>
      </div>
    </section>
  );
};

export default IconSection;
