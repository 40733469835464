import type { IconProps } from './IconProps';

const PhoneIcon = ({ color = '#344054' }: IconProps) => (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none">
    <path
      d="M11.2084 5.00008C12.0223 5.15889 12.7704 5.55696 13.3568 6.14336C13.9432 6.72976 14.3412 7.4778 14.5 8.29175M11.2084 1.66675C12.8994 1.85461 14.4764 2.61189 15.6802 3.81425C16.8841 5.01662 17.6434 6.59259 17.8334 8.28341M8.02253 11.5526C7.02121 10.5513 6.23055 9.41912 5.65056 8.21111C5.60067 8.1072 5.57572 8.05524 5.55656 7.9895C5.48846 7.75587 5.53737 7.46899 5.67905 7.27113C5.71892 7.21546 5.76655 7.16783 5.86181 7.07257C6.15315 6.78123 6.29881 6.63556 6.39405 6.48908C6.75322 5.93667 6.75321 5.22452 6.39405 4.67211C6.29881 4.52563 6.15315 4.37996 5.86181 4.08862L5.69942 3.92623C5.25655 3.48336 5.03511 3.26192 4.7973 3.14164C4.32433 2.90241 3.76577 2.90241 3.29281 3.14164C3.05499 3.26192 2.83355 3.48336 2.39069 3.92623L2.25932 4.05759C1.81797 4.49894 1.59729 4.71962 1.42875 5.01964C1.24174 5.35257 1.10727 5.86964 1.10841 6.25149C1.10943 6.59562 1.17618 6.8308 1.30969 7.30117C2.02716 9.82901 3.38089 12.2143 5.37088 14.2043C7.36086 16.1943 9.74616 17.548 12.274 18.2655C12.7444 18.399 12.9795 18.4657 13.3237 18.4668C13.7055 18.4679 14.2226 18.3334 14.5555 18.1464C14.8555 17.9779 15.0762 17.7572 15.5176 17.3158L15.6489 17.1845C16.0918 16.7416 16.3132 16.5202 16.4335 16.2824C16.6728 15.8094 16.6728 15.2508 16.4335 14.7779C16.3132 14.5401 16.0918 14.3186 15.6489 13.8758L15.4865 13.7134C15.1952 13.422 15.0495 13.2764 14.9031 13.1811C14.3506 12.8219 13.6385 12.822 13.0861 13.1811C12.9396 13.2764 12.7939 13.422 12.5026 13.7134C12.4073 13.8086 12.3597 13.8562 12.304 13.8961C12.1062 14.0378 11.8193 14.0867 11.5857 14.0186C11.5199 13.9994 11.468 13.9745 11.3641 13.9246C10.156 13.3446 9.02384 12.554 8.02253 11.5526Z"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PhoneIcon;
