import './hero.scss';
import Pill from '../Pill';
import ButtonSection from '../buttonSection';
import type { ButtonProps } from '../button';
import type { FC, ReactElement } from 'react';

import HeroBackground from './components/HeroBackground/HeroBackground';

type Props = {
  /** The heading of the page */
  h1: string;
  /** Text that appears in the pill above the page heading */
  preText: string;
  /** The background image to pass as the hero image; if null passed, a skeleton loader is displayed */
  svg?: string;
  /** Display a loader while the SVG is loading */
  svgLoading?: boolean;
  /** The paragraph(s) summarizing the page, represented as an array of strings */
  paragraphs: string[];
  /**
   * Position of the focal object for hero image when smaller viewports dictate a background-size of 'cover'.
   * Accepts CSS background-position string https://developer.mozilla.org/en-US/docs/Web/CSS/background-position
   */
  focalPosition: string;
  /** A button passed as JSX to display in the hero */
  sectionButton?: ReactElement<ButtonProps>;
};

const Hero: FC<Props> = ({ h1, svg, svgLoading, preText, paragraphs, focalPosition, sectionButton }) => {
  const isTabletOrBelow = window.innerWidth <= 900;

  return (
    <HeroBackground>
      <div className="container">
        <ButtonSection
          svg={svg}
          header={h1}
          shape={'square'}
          svgLoading={svgLoading}
          paragraphs={paragraphs}
          summaryPlacement="left"
          colorNthAmountOfEndWords={0}
          sectionButton={sectionButton}
          preText={<Pill>{preText}</Pill>}
          buttonPlacement={['left', 'bottom']}
          svgPosition={isTabletOrBelow ? focalPosition : 'center'}
        />
      </div>
    </HeroBackground>
  );
};

export default Hero;
