import './MeetOurTeam.scss';
import { useLayoutEffect, useState } from 'react';
import TeamMember from '../../components/TeamMember';
import docText from '../../../../i18n/documentText.json';
import { useHighlightedHeader } from '../../../../hooks/useHighlightedHeader';
import TeamMemberSkeletonLoader from '../../components/TeamMemberSkeletonLoader';

type DocTeamMember = {
  name: string;
  title: string;
};

type TeamMember = DocTeamMember & { imageSrc: string };

type TeamMembers = {
  [key: string]: DocTeamMember;
};

const docTextTeamMembers = Object.values(docText.about.section3.team as TeamMembers);

const MeetOurTeam = () => {
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);

  useLayoutEffect(() => {
    const loadTeamMembers = async () => {
      const team: TeamMember[] = [];
      for (const teamMember of docTextTeamMembers) {
        const image = await import(`./images/${teamMember.name}.png`);
        team.push({
          ...teamMember,
          imageSrc: image.default,
        });
      }

      return team;
    };

    (async () => setTeamMembers(await loadTeamMembers()))();
  }, []);

  const { HighlightedHeader } = useHighlightedHeader({
    text: docText.about.section3.h1,
    colorNthAmountOfEndWords: 1,
  });

  return (
    <section className="meet-our-team-container">
      {HighlightedHeader}
      <p>{docText.about.section3.p}</p>

      <div className="grid">
        <>
          {teamMembers.length === 0
            ? docTextTeamMembers.map(({ name, title }) => (
                <TeamMemberSkeletonLoader key={name} name={name} title={title} />
              ))
            : teamMembers.map(({ name, title, imageSrc }) => (
                <TeamMember key={name} name={name} title={title} imageSrc={imageSrc ?? undefined} />
              ))}
        </>
      </div>
    </section>
  );
};

export default MeetOurTeam;
