import clx from 'classnames';
import type { FC } from 'react';
import './BooleanQuizQuestion.scss';
import type { Answer } from '../Quiz/hooks/useQuiz';
import { Button } from '../../../../components/button';
import ThumbUpIcon from '../../../../components/icons/ThumbUp'; // TODO: convert to DRY icon

export type BooleanQuizQuestionProps = {
  questionObject: Answer;
  onAnswer: (answer: boolean) => void;
};

const BooleanQuizQuestion: FC<BooleanQuizQuestionProps> = ({ questionObject, onAnswer }) => (
  <div className="boolean-quiz-question">
    <h3 className="boolean-quiz-question-header">{questionObject.question}</h3>
    <div className="boolean-quiz-question-answers">
      <Button
        variant="outlined"
        onClick={() => onAnswer(true)}
        adornmentLeft={<ThumbUpIcon />}
        className={clx('yes', {
          selected: questionObject.answer === true,
        })}
      >
        Yes
      </Button>
      <Button
        variant="outlined"
        onClick={() => onAnswer(false)}
        adornmentLeft={<ThumbUpIcon />}
        className={clx('no', {
          selected: questionObject.answer === false,
        })}
      >
        No
      </Button>
    </div>
  </div>
);

export default BooleanQuizQuestion;
