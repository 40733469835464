import './contactUs.scss';
import { type ReactNode } from 'react';
import ContactInfo from './components/ContactInfo';
import docText from '../../i18n/documentText.json';
import StyledIcon from '../../components/styledIcon';
import EmailIcon from '../../components/icons/EmailIcon';
import PhoneIcon from '../../components/icons/PhoneIcon';
import ButtonSection from '../../components/buttonSection';
import LocationIcon from '../../components/icons/LocationIcon';
import AppointmentForm from '../../assemblies/AppointmentForm';
import HeroBackground from '../../components/Hero/components/HeroBackground';

type ContactInfo = {
  [key: string]: {
    label: string;
    value: string[];
  };
};

const contactInfoIcons: {
  [key: string]: ReactNode;
} = {
  email: <StyledIcon svg={<EmailIcon />} outerSize={56} innerSize={40} />,
  phone: <StyledIcon svg={<PhoneIcon />} outerSize={56} innerSize={40} />,
  address: <StyledIcon svg={<LocationIcon />} outerSize={56} innerSize={40} />,
};

const ContactUs = () => (
  <main className="contact-us-page">
    <HeroBackground>
      <section className="contact-us-form">
        <header>
          <h3 className="super-header">{docText.contactUs.form.superHeader}</h3>
          <h1 className="main-heading">{docText.contactUs.form.h1}</h1>
          <h2 className="sub-heading">{docText.contactUs.form.h2}</h2>
        </header>

        <AppointmentForm labelColor="light-contrast" />
      </section>

      <ButtonSection
        shape={null}
        preText={docText.contactUs.hero.superHeader}
        header={docText.contactUs.hero.h1}
        paragraphs={docText.contactUs.hero.h2}
      />

      <div className="contact-us-page-grid">
        {Object.keys(docText.contactUs.contactInfo).map((key) => (
          <ContactInfo
            key={key}
            Icon={contactInfoIcons[key]}
            label={(docText.contactUs.contactInfo as ContactInfo)[key].label}
            value={(docText.contactUs.contactInfo as ContactInfo)[key].value}
          />
        ))}
      </div>

      <div className="contact-us-map-embed">
        <iframe
          width="100%"
          height="100%"
          loading="lazy"
          style={{ border: 0 }}
          allowFullScreen={false}
          src={docText.contactUs.mapEmbedUrl}
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </HeroBackground>
  </main>
);

export default ContactUs;
