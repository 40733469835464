import { useRef } from 'react';
import Hero from '../../components/Hero/hero';
import { Button } from '../../components/button';
import docText from '../../i18n/documentText.json';
import MeetOurTeam from './assemblies/MeetOurTeam';
import AboutDrLaFonte from './assemblies/AboutDrLaFonte';
import AboutAltitudeDental from './assemblies/AboutAltitudeDental';
import BookFreeConsultation from './assemblies/BookFreeConsultation';
import GetReadyForYourAppointment from './assemblies/GetReadyForYourAppointment';
import happyWoman from '../../svg/happy-woman-holding-blue-retainer-in-dental-clinic-2022-12-30-02-21-51-utc@3x.svg';

const AboutPage = () => {
  const scrollToElement = useRef<HTMLDivElement>(null);

  const handleHeroCTAClick = () => {
    if (scrollToElement && scrollToElement.current) scrollToElement.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <Hero
        svg={happyWoman}
        focalPosition="25% center"
        h1={docText.about.hero.h1}
        paragraphs={docText.about.hero.h2}
        preText={docText.about.hero.superHeader}
        sectionButton={<Button onClick={handleHeroCTAClick} children={<p>{docText.about.hero.button}</p>} />}
      />

      <AboutAltitudeDental />
      <GetReadyForYourAppointment ref={scrollToElement} />
      <MeetOurTeam />
      <AboutDrLaFonte />
      <BookFreeConsultation />
    </>
  );
};

export default AboutPage;
