import './MobileNavCTA.scss';
import { Button } from '../../../../components/button';
import { forwardRef, type ForwardedRef } from 'react';
import docText from '../../../../i18n/documentText.json';

const MobileNavCTA = forwardRef((_, ref: ForwardedRef<HTMLElement>) => (
  <section ref={ref} className="mobile-nav-cta">
    <p>{docText.topBanner.text1}</p>
    <p>{docText.topBanner.text3}</p>

    <Button linkTo="/book-online" hashLink smooth variant="solid">
      Book Online
    </Button>
  </section>
));

export default MobileNavCTA;
