import './Grid.scss';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import Document from '../../Document';
import { forwardRef, useMemo } from 'react';
import { type Grid as GridType } from '../../types/Document';
import { useDocumentContentStyle } from 'pages/procedures/hooks/useDocumentContentStyle';

const Grid = forwardRef<HTMLDivElement, Omit<GridType, 'contentType'>>(
  ({ items, columnCount, iconsDirectory, imagesDirectory, className, style }, ref) => {
    const styleProp = useDocumentContentStyle({ style });

    const GridItems = useMemo(
      () =>
        items.map((item) => {
          const id = uuid();
          return (
            <div className="grid-item" key={id}>
              <Document content={item} iconsDirectory={iconsDirectory} imagesDirectory={imagesDirectory} />
            </div>
          );
        }),
      [items, imagesDirectory, iconsDirectory]
    );

    return (
      <div
        ref={ref}
        className={classNames('grid', className)}
        style={{
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
          ...styleProp,
        }}
      >
        {GridItems}
      </div>
    );
  }
);

export default Grid;
