import Hero from 'components/Hero';
import './ProcedureCategoryPage.scss';
import Topics from '../components/Topics';
import { Button } from 'components/button';
import InfoBox from './components/InfoBox';
import docText from 'i18n/documentText.json';
import type { Topic } from '../components/Topics';
import Document from '../../../components/Document';
import DiscoverMore from '../components/DiscoverMore';
import AppointmentForm from '../assemblies/AppointmentForm';
import { type FC, useLayoutEffect, useMemo, useState } from 'react';
import HeroBackground from 'components/Hero/components/HeroBackground';
import type { Paragraph } from '../../../components/Document/types/Document';
import type { DiscoverMoreProcedure } from '../components/DiscoverMore/types';
import type { Category, IndexableCategory } from '../../../components/Document/types/Category';

type ProcedureCategoryPageProps = {
  category: Category;
  /** How the page will display the topic in the bottom info box */
  infoBoxTopic: string;
  allCategories: IndexableCategory;
};

const ProcedureCategoryPage: FC<ProcedureCategoryPageProps> = ({
  infoBoxTopic,
  allCategories,
  category: { categoryName, hero, procedures, layout },
}) => {
  const [heroImage, setHeroImage] = useState<string>();

  /** Dynamically import the hero image */
  useLayoutEffect(() => {
    const loadHeroImage = async () => await import(`../../../../public/images/procedures/${hero.image}`);
    (async () => {
      const image = await loadHeroImage();
      setHeroImage(image.default);
    })();
  }, [hero.image]);

  const heroParagraphs: string[] = useMemo(
    () =>
      (hero.text as Paragraph[]).map(({ text }) => {
        if (typeof text === 'string') return text;

        /**
         * TODO: This covers most use cases, but ignores any formatting; make the Hero
         * component (and underlying ButtonSection component) `paragraphs` prop accept
         * the new Document typing and implement formatting.
         *
         * Edge cases this would address:
         * - Periodontal Disease page
         *    - gramatically speaking, 'periodontal' should be italicized on the Periodontal Disease page in the first paragraph ("The word periodonal means...")
         *    - "number one reason" was italicized on the Periodontal Disease page in the third paragraph
         */
        return text.join(' ');
      }),
    [hero.text]
  );

  /**
   * Most top-level categories have many procedures under them; but some have none; in
   * this event, list the other categories instead, filtering out this one. This filtering
   * logic, in addition to choosing between whether to show categories or individual
   * procedures supports multiple UIs on this page.
   */
  const topics: (Topic & DiscoverMoreProcedure)[] = useMemo(() => {
    const noProceduresForThisCategory = Object.keys(procedures).length === 0;

    if (noProceduresForThisCategory) {
      const allOtherCategories = Object.keys(allCategories).reduce((acc: (Topic & DiscoverMoreProcedure)[], key) => {
        if (allCategories[key].categoryName !== categoryName)
          return [
            ...acc,
            {
              path: key,
              imageSrc: allCategories[key].hero.image,
              name: allCategories[key].categoryName,
              imageAlt: allCategories[key].hero.imageAltText,
              shortDescription: allCategories[key].shortDescription,
            },
          ];

        return acc;
      }, []);

      return allOtherCategories;
    }

    return Object.keys(procedures).map((key) => ({
      path: key,
      name: procedures[key].procedureName,
      imageSrc: procedures[key].discoverMore.imageSrc,
      imageAlt: procedures[key].discoverMore.imageAlt,
      shortDescription: procedures[key].discoverMore.shortDescription,
    }));
  }, [procedures, allCategories, categoryName]);

  return (
    <div className="procedure-category-page">
      <Hero
        svg={heroImage}
        h1={categoryName}
        focalPosition="top center"
        paragraphs={heroParagraphs}
        preText={docText.procedures.preText}
        sectionButton={<Button onClick={() => null} children={<></>} />}
      />

      <div className="layout">
        <aside>
          <Topics topics={topics} />
        </aside>

        <main>
          <Document iconsDirectory="src/components/icons" imagesDirectory="images/procedures" content={layout} />
          <InfoBox topic={infoBoxTopic} />
          <AppointmentForm />
        </main>
      </div>

      <HeroBackground>
        <DiscoverMore procedures={topics} />
      </HeroBackground>
    </div>
  );
};

export default ProcedureCategoryPage;
