/** TODO: Replace with Dry icon  */

import type { IconProps } from './IconProps';

const ArrowRight = ({ color }: IconProps) => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none">
    <path
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M1.66602 6.99996H13.3327M13.3327 6.99996L7.49935 1.16663M13.3327 6.99996L7.49935 12.8333"
    />
  </svg>
);

export default ArrowRight;
