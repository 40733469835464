import './AboutAltitudeDental.scss';
import Blurb from '../../components/Blurb';
import docText from '../../../../i18n/documentText.json';
import ToothFile from '../../../../components/icons/ToothFile';
import ToothLove from '../../../../components/icons/ToothLove';
import ToothShine from '../../../../components/icons/ToothShine';
import ToothChecked from '../../../../components/icons/ToothChecked';
import ToothCoating from '../../../../components/icons/ToothCoating';
import { useHighlightedHeader } from '../../../../hooks/useHighlightedHeader';

const blurbsWithIcons = [
  {
    Icon: <ToothShine />,
    p: docText.about.section1.blurbs['1'].p,
    h2: docText.about.section1.blurbs['1'].h2,
  },
  {
    Icon: <ToothChecked />,
    p: docText.about.section1.blurbs['2'].p,
    h2: docText.about.section1.blurbs['2'].h2,
  },
  {
    Icon: <ToothCoating />,
    p: docText.about.section1.blurbs['3'].p,
    h2: docText.about.section1.blurbs['3'].h2,
  },
  {
    Icon: <ToothFile />,
    p: docText.about.section1.blurbs['4'].p,
    h2: docText.about.section1.blurbs['4'].h2,
  },
  {
    Icon: <ToothLove />,
    p: docText.about.section1.blurbs['5'].p,
    h2: docText.about.section1.blurbs['5'].h2,
  },
];

const AboutAltitudeDental = () => {
  const { HighlightedHeader } = useHighlightedHeader({
    text: docText.about.section1.h1,
    colorNthAmountOfEndWords: 2,
  });

  return (
    <section className="about-altitude-dental-container">
      <h2 className="pretext">{docText.about.section1.superHeader}</h2>
      {HighlightedHeader}

      <div className="grid">
        {blurbsWithIcons.map(({ p, h2, Icon }) => (
          <div key={h2} className="grid-item-container">
            <Blurb heading={h2} paragraph={p} Icon={Icon} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default AboutAltitudeDental;
