export const env: {
  [key: string]: {
    API_HOST: string;
  };
} = {
  /** Production */
  'https://main.d249gq7hsnni4n.amplifyapp.com': {
    API_HOST: 'https://altitude-dental-server-main.onrender.com',
  },
  /** Staging */
  'https://staging.d36lrra2wdtqz4.amplifyapp.com': {
    API_HOST: 'https://altitude-dental-server-staging.onrender.com',
  },
  /** Dev */
  'https://dev.d2s5soic4h0syv.amplifyapp.com': {
    API_HOST: 'https://altitude-dental-server-dev.onrender.com',
  },
  /** Local */
  'http://localhost:3000': {
    API_HOST: 'http://localhost:3001',
  },
};
