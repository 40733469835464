import './CallUsButton.scss';
import { Button } from '../../components/button';
import PhoneIcon from '../../svg/phone-icon.svg';
import docText from '../../i18n/documentText.json';

const CallUsButton = () => (
  <Button
    className="call-us-button"
    linkTo={`tel:+${docText.phoneNumber}`}
    adornmentLeft={<div className="phone-button-adornment" style={{ backgroundImage: `url(${PhoneIcon})` }} />}
  >
    {docText.about.sharedCTAButton}
  </Button>
);

export default CallUsButton;
