import './AboutDrLaFonte.scss';
import AboutDrLaFonteImage from './images/AboutDrLaFonte.png';
import docText from '../../../../i18n/documentText.json';

type AboutDrLaFonteParagraph = {
  [key: string]: string;
};

const AboutDrLaFonte = () => (
  <section className="about-dr-lafonte">
    <img src={AboutDrLaFonteImage} alt="AcoutDr. LaFonte" />
    <h2>{docText.about.section4.superHeader}</h2>
    <h1>{docText.about.section4.h1}</h1>
    <p className="summary">{docText.about.section4.summary}</p>

    <div className="column-layout">
      {Object.keys(docText.about.section4.paragraphs).map((key, index) => (
        <p key={index}>{(docText.about.section4.paragraphs as AboutDrLaFonteParagraph)[key]}</p>
      ))}
    </div>
  </section>
);

export default AboutDrLaFonte;
