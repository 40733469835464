import Collapsible from 'react-collapsible';
import './Accordion.scss';
import { Button } from '../../../../components/button';
import Chevron from '../../../../components/icons/Chevron';
import { useEffect, useState, type FC, type ReactNode } from 'react';

type AccordionProps = {
  /** If the parent of the accordion is has its own link, include its path */
  parentContent: ReactNode | ReactNode[];
  /** The children elements to be passed to the Accordion */
  children: ReactNode | ReactNode[];
  /** A function to be called when the Accordion's open / closed state changes */
  onOpenStateChange?: (open: boolean) => void;
  /** Whether the Accordion is open / closed initially */
  initialOpenState?: boolean;
  /** Controls whether the Accordion can open / close from its initialOpenState */
  collapsible?: boolean;
};

const Accordion: FC<AccordionProps> = ({
  parentContent,
  children,
  initialOpenState,
  onOpenStateChange,
  collapsible = true,
}: AccordionProps) => {
  const [open, setOpen] = useState(initialOpenState ?? false);

  useEffect(() => {
    if (onOpenStateChange && collapsible) onOpenStateChange(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOpenStateChange, open]);

  return (
    <Collapsible
      open={open}
      className="accordion"
      triggerDisabled={!collapsible}
      trigger={
        <>
          {parentContent}
          {collapsible && (
            <Button variant="icon" onClick={() => setOpen(!open)}>
              <Chevron color="#667085" />
            </Button>
          )}
        </>
      }
    >
      {children}
    </Collapsible>
  );
};

export default Accordion;
