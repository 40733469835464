import './PatientFormContent.scss';
import docText from '../../../../i18n/documentText.json';
import DownloadCard from '../../components/DownloadCard/DownloadCard';
import { useManageMultiElementDynamicContentHeight } from '../../../../hooks/useManageMultiElementDynamicContentHeight';

type Forms = {
  [key: string]: {
    link: string;
    labelText: string;
  };
};

const PatientFormContent = () => {
  const { refs, minHeight } = useManageMultiElementDynamicContentHeight({
    numberOfElements: Object.keys(docText.form.forms).length,
  });

  return (
    <section className="patient-form-content">
      {Object.keys(docText.form.forms).map((key, index) => (
        <DownloadCard
          key={key}
          minTitleHeight={minHeight ?? 0}
          ref={(el) => (refs.current[index] = el)}
          path={(docText.form.forms as Forms)[key].link}
          cardTitle={(docText.form.forms as Forms)[key].labelText}
        />
      ))}
    </section>
  );
};

export default PatientFormContent;
