import type { FC, ReactNode } from 'react';

type Props = {
  /** CSS color string; default: '#F2F4F7' (Sass: $hero-background) */
  backgroundColor?: string;
  /** Hero content */
  children: ReactNode | ReactNode[];
};

const HeroBackground: FC<Props> = ({ children, backgroundColor = '#F2F4F7' }) => (
  <div className="hero" style={{ backgroundColor }}>
    {children}
  </div>
);

export default HeroBackground;
