import { useMemo } from 'react';

/**
 * @param desiredContrastColor
 * @returns the spec color for the desired contrast color. Either:
 *  - $font-color-secondary (#344054) for 'light-contrast', or
 *  - $hero-background (#F2F4F7) for 'dark-contrast'
 */
export const useInputLabelContrastColor = (desiredContrastColor?: 'light-contrast' | 'dark-contrast') => {
  return useMemo(() => {
    const color = desiredContrastColor === 'dark-contrast' ? 'dark-contrast' : 'light-contrast';
    return color === 'dark-contrast' ? '#F2F4F7' : '#344054';
  }, [desiredContrastColor]);
};
