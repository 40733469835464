import './SteppedLinearProgress.scss';
import { useMemo, type FC, type ReactNode } from 'react';

export type SteppedLinearProgressProps = {
  /** The user's current progress */
  currentStep: number;
  /** The total number of steps in the stepper */
  numberOfSteps: number;
  /** The element(s) to display to the left of the progress bar; default "Progress" */
  LabelLeft?: ReactNode | ReactNode[];
  /** The element(s) to display to the left of the progress bar; default `${progress}/${numberOfSteps}` */
  LabelRight?: ReactNode | ReactNode[];
};

const SteppedLinearProgress: FC<SteppedLinearProgressProps> = ({
  LabelLeft,
  LabelRight,
  currentStep,
  numberOfSteps,
}) => {
  const computedPercentage = useMemo(() => {
    /** Can't divide by zero, so default to 100% done */
    if (numberOfSteps === 0) return 100;

    return (currentStep / numberOfSteps) * 100;
  }, [currentStep, numberOfSteps]);

  const LeftLabel = useMemo(
    () => LabelLeft ?? <span className="stepped-linear-progress-label">Progress</span>,
    [LabelLeft]
  );

  const RightLabel = useMemo(
    () => LabelRight ?? <span className="stepped-linear-progress-label">{`${currentStep}/${numberOfSteps}`}</span>,
    [LabelRight, currentStep, numberOfSteps]
  );

  return (
    <div className="stepped-linear-progress">
      <div className="stepped-linear-progress-row">
        <div className="stepped-linear-progress-row">{LeftLabel}</div>

        <div className="progress-bar">
          <div className="progress-bar-completed" style={{ width: `${computedPercentage}%` }} />
        </div>

        <div className="stepped-linear-progress-row">{RightLabel}</div>
      </div>
    </div>
  );
};

export default SteppedLinearProgress;
