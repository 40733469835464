import { type FC } from 'react';
import SkeletonImage from 'components/SkeletonImage';

type Props = {
  name: string;
  title: string;
};
const TeamMemberSkeletonLoader: FC<Props> = ({ name, title }) => {
  return (
    <div className="team-member">
      <SkeletonImage />
      <h3>{name}</h3>
      <p>{title}</p>
    </div>
  );
};

export default TeamMemberSkeletonLoader;
