import './ProcedurePage.scss';
import { type FC, useMemo } from 'react';
import Document from '../../../components/Document';
import type { Topic } from '../components/Topics';
import DiscoverMore from '../components/DiscoverMore';
import AppointmentForm from '../assemblies/AppointmentForm';
import HeroBackground from 'components/Hero/components/HeroBackground';
import type { Procedure, IndexableProcedure } from '../../../components/Document/types/Procedure';
import type { DiscoverMoreProcedure } from '../components/DiscoverMore/types';

type ProcedurePageProps = {
  allProcedures: IndexableProcedure;
  procedure: Procedure;
};

const ProcedurePage: FC<ProcedurePageProps> = ({ allProcedures, procedure: { procedureName, layout } }) => {
  /** Return all procedures, excluding the one currently shown. Reduce is used to filter and map in same iterator. */
  const topics: (Topic & DiscoverMoreProcedure)[] = useMemo(() => {
    const allOtherProcedures = Object.keys(allProcedures).reduce((acc: (Topic & DiscoverMoreProcedure)[], key) => {
      if (allProcedures[key].procedureName !== procedureName)
        return [
          ...acc,
          {
            path: key,
            name: allProcedures[key].procedureName,
            imageSrc: allProcedures[key].discoverMore.imageSrc,
            imageAlt: allProcedures[key].discoverMore.imageAlt,
            shortDescription: allProcedures[key].discoverMore.shortDescription,
          },
        ];

      return acc;
    }, []);

    return allOtherProcedures;
  }, [allProcedures, procedureName]);

  return (
    <div className="procedure-page">
      <main className="layout">
        <Document iconsDirectory="src/components/icons" imagesDirectory="images/procedures" content={layout} />
        <AppointmentForm />
      </main>

      <HeroBackground>
        <DiscoverMore procedures={topics} />
      </HeroBackground>
    </div>
  );
};

export default ProcedurePage;
