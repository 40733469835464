import './Columns.scss';
import classNames from 'classnames';
import Document from '../../Document';
import { forwardRef } from 'react';
import { type Columns as ColumnsType } from '../../types/Document';
import { useDocumentContentStyle } from 'pages/procedures/hooks/useDocumentContentStyle';

const Columns = forwardRef<HTMLDivElement, Omit<ColumnsType, 'contentType'>>(
  ({ content, columnCount, style, iconsDirectory, imagesDirectory, ...props }, ref) => {
    const styleProp = useDocumentContentStyle({
      style: {
        columnCount,
        ...style,
      },
    });

    return (
      <div {...props} style={styleProp} ref={ref} className={classNames('columns', props.className)}>
        <Document imagesDirectory={imagesDirectory} iconsDirectory={iconsDirectory} content={content} />
      </div>
    );
  }
);

export default Columns;
