import './GetReadyForYourAppointment.scss';
import { forwardRef, ForwardedRef } from 'react';
import docText from '../../../../i18n/documentText.json';
import AppointmentForm from '../../../../assemblies/AppointmentForm';
import CallUsButton from '../../../../assemblies/CallUsButton';

const GetReadyForYourAppointment = forwardRef((_, ref: ForwardedRef<HTMLDivElement>) => (
  <section id="appointment" ref={ref} className="get-ready-for-your-appointment">
    <div className="content">
      <h1>{docText.about.section2.h1}</h1>
      <p>{docText.about.section2.p}</p>
      <div className="content">
        <CallUsButton />
        <AppointmentForm />
      </div>
    </div>
  </section>
));

export default GetReadyForYourAppointment;
