import './InfoBox.scss';
import { type FC } from 'react';
import docText from 'i18n/documentText.json';

type InfoBoxProps = {
  topic: string;
};

/**
 * The info box at the bottom of the page, which displays the topic of the page
 * within a template string.
 */
const InfoBox: FC<InfoBoxProps> = ({ topic }) => (
  <div className="info-box">
    <p className="paragraph">{docText.procedures.infoBoxTemplate.replace('{{{topic}}}', topic)}</p>
  </div>
);

export default InfoBox;
