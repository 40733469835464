import './Span.scss';
import { v4 as uuid } from 'uuid';
import { forwardRef, useMemo } from 'react';
import type { Span as SpanType } from 'components/Document/types/Document';
import { useDocumentContentStyle } from '../../../../pages/procedures/hooks/useDocumentContentStyle';

const Span = forwardRef<HTMLParagraphElement, Omit<SpanType, 'hierarchy' | 'contentType'>>(
  ({ text, style, ...props }, ref) => {
    const styleProp = useDocumentContentStyle({ style });

    /** Differentiate between raw text and nested spans, ensuring members have unique keys */
    const Text = useMemo(() => {
      if (typeof text === 'string') return text;

      /** Omit non-DOM related props to suppress "React does not recognize the `` prop on a DOM element warnings */
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      return text.map(({ contentType, ...nestedTextProps }) => {
        const key = uuid();
        return <Span key={key} {...nestedTextProps} />;
      });
    }, [text]);

    return (
      <span className="span" ref={ref} {...props} style={styleProp}>
        {Text}
      </span>
    );
  }
);

export default Span;
