/** TODO: Replace with Dry icon  */

import type { IconProps } from './IconProps';

const Chevron = ({ color }: IconProps) => (
  <svg className="chevron" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M5 7.5L10 12.5L15 7.5" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default Chevron;
