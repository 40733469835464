import './Heading.scss';
import Span from '../Span';
import { v4 as uuid } from 'uuid';
import classNames from 'classnames';
import { useMemo, forwardRef } from 'react';
import type { Heading as HeadingType } from 'components/Document/types/Document';
import { useDocumentContentStyle } from 'pages/procedures/hooks/useDocumentContentStyle';

const Heading = forwardRef<HTMLHeadingElement, Omit<HeadingType, 'contentType'>>(
  ({ style, hierarchy, text, ...props }, ref) => {
    const styleProp = useDocumentContentStyle({ style });

    /** Differentiate between raw text and nested spans, ensuring members have unique keys */
    const Text = useMemo(() => {
      if (typeof text === 'string') return text;

      const key = uuid();
      return text.map(({ ...nestedTextProps }) => <Span key={key} {...nestedTextProps} />);
    }, [text]);

    /** Differentiate which heading hierarchy (h1, h2, h3...) is used */
    const HeadingLevel = useMemo(() => {
      const sharedProps = {
        ref,
        style: styleProp,
        className: classNames('heading', hierarchy, props.className),

        ...props,
      };

      switch (hierarchy) {
        case 'h1':
          return <h1 {...sharedProps}>{Text}</h1>;
        case 'h2':
          return <h2 {...sharedProps}>{Text}</h2>;
        case 'h3':
          return <h3 {...sharedProps}>{Text}</h3>;
        case 'h4':
          return <h4 {...sharedProps}>{Text}</h4>;
        case 'h5':
          return <h5 {...sharedProps}>{Text}</h5>;
        case 'h6':
          return <h6 {...sharedProps}>{Text}</h6>;
        default:
          throw new Error(
            `Unsupported heading hierarchy. Expected 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'. Received: ${hierarchy}.`
          );
      }
    }, [ref, styleProp, props, hierarchy, Text]);

    return HeadingLevel;
  }
);

export default Heading;
