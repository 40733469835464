import './patientForm.scss';
import Hero from '../../components/Hero';
import { Button } from '../../components/button';
import docText from '../../i18n/documentText.json';
import PhoneIcon from '../../components/icons/PhoneIcon'; // TODO: replace with DRY icon
import PatientFormContent from './assemblies/PatientFormContent';
import dentistForm from '../../svg/dentist-making-up-a-medication-form-for-the-patient-2PMLERU@2x.svg';

const Form = () => {
  return (
    <main className="patient-form-page">
      <Hero
        svg={dentistForm}
        focalPosition="left"
        h1={docText.form.hero.h1}
        paragraphs={docText.form.hero.h2}
        preText={docText.form.hero.superHeader}
        sectionButton={
          <Button linkTo={`tel:+${docText.phoneNumber}`} adornmentLeft={<PhoneIcon color="#ffffff" />}>
            {docText.form.hero.button}
          </Button>
        }
      />

      <PatientFormContent />
    </main>
  );
};

export default Form;
