import './index.scss';
import { navRoutes } from './routes';
import ReactDOM from 'react-dom/client';
import Footer from './components/footer';
import NavBar from './components/NavBar';
import { flattenedRoutes } from './routes';
import reportWebVitals from './reportWebVitals';
import LayoutContextProvider from 'context/Layout';
import { Route, Routes, BrowserRouter, type PathRouteProps } from 'react-router-dom';
import { ScrollToTopOnNavigation } from 'hooks/useScrollToTopOnNavigation';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <ScrollToTopOnNavigation />

    <LayoutContextProvider>
      <NavBar navRoutes={navRoutes} />
      <Routes>
        {flattenedRoutes.map((route: PathRouteProps, i) => (
          <Route key={i} path={route.path} element={route.element} />
        ))}
      </Routes>
      <Footer />
    </LayoutContextProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
