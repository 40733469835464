import './DownloadCard.scss';
import Card from '../../../../components/Card';
import { forwardRef, type ForwardedRef } from 'react';
import { Button } from '../../../../components/button';
import DownloadIcon from '../../../../components/icons/DownloadIcon';

type Props = {
  path: string;
  cardTitle: string;
  minTitleHeight: number;
};

const DownloadCard = forwardRef(({ cardTitle, path, minTitleHeight }: Props, ref: ForwardedRef<HTMLDivElement>) => (
  <Card className="download-card">
    <div ref={ref} className="card-title">
      <h3 style={{ height: 'auto', minHeight: minTitleHeight }}>{cardTitle}</h3>
    </div>

    <div className="card-body">
      <Button
        linkTo={path}
        target="_blank"
        variant="outlined"
        rel="noopener noreferrer"
        adornmentRight={<DownloadIcon />}
      >
        Download
      </Button>
    </div>
  </Card>
));

export default DownloadCard;
