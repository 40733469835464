import './DiscoverMoreListItem.scss';
import { Button } from 'components/button';
import docText from 'i18n/documentText.json';
import SkeletonImage from 'components/SkeletonImage';
import ArrowUpRightIcon from 'components/icons/LinkIcon';
import { type DiscoverMoreProcedure } from '../../types';
import { type FC, useLayoutEffect, useState, useMemo } from 'react';

export type DiscoverMoreListItemProps = {
  procedure: DiscoverMoreProcedure;
};

const DiscoverMoreListItem: FC<DiscoverMoreListItemProps> = ({
  procedure: { imageSrc, name, shortDescription, path },
}) => {
  const [image, setImage] = useState<string | undefined>(undefined);

  useLayoutEffect(() => {
    const loadImage = async (src: string) => await import(`/public/images/procedures/${src}`);
    (async () => setImage((await loadImage(imageSrc)).default))();
  }, [imageSrc]);

  const Image = useMemo(() => {
    if (!image) return <SkeletonImage />;

    return <div className="image" style={{ backgroundImage: `url(${image})` }} />;
  }, [image]);

  return (
    <li className="discover-more-list-item">
      {Image}
      <p className="super-header">{docText.procedures.discoverMore.listItemSuperHeader}</p>

      <header>
        <h2>{name}</h2>
        <Button variant="icon" className="icon-button" linkTo={path}>
          <ArrowUpRightIcon />
        </Button>
      </header>

      <p className="description">{shortDescription}</p>
    </li>
  );
};

export default DiscoverMoreListItem;
