import './pill.scss';
import classNames from 'classnames';
import { forwardRef, type ReactNode, type HTMLAttributes } from 'react';

type Props = HTMLAttributes<HTMLDivElement> & {
  /** CSS color string; default '#51226D' (Sass: $link-purple) */
  color?: string;
  /** CSS color string; default '#E5D2EF' (Sass: $light-purple) */
  borderColor?: string;
  /** CSS color string; default '#F0E6F6' (Sass: $light-light-purple)  */
  backgroundColor?: string;
  /** Pill content */
  children: ReactNode | ReactNode[];
};

const Pill = forwardRef<HTMLDivElement, Props>(
  ({ backgroundColor = '#F0E6F6', borderColor = '#E5D2EF', color = '#51226D', children, style, className }, ref) => (
    <div
      ref={ref}
      style={{
        color,
        borderColor,
        backgroundColor,

        ...style,
      }}
      className={classNames('pill', className)}
    >
      {children}
    </div>
  )
);

export default Pill;
