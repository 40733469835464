import type { FC } from 'react';
import './TabletSubNavigationContent.scss';
import SubNavigationComponent from '../SubNavigation';
import type { SubNavigation } from '../../../../routes';

type Props = {
  subNavigation: SubNavigation;
};

const TabletSubNavigationContent: FC<Props> = ({ subNavigation }) => {
  return (
    <div className="tablet-sub-navigation-container">
      <SubNavigationComponent subNavigation={subNavigation} />
    </div>
  );
};

export default TabletSubNavigationContent;
