import './NavBar.scss';
import Logo from '../Logo';
import { Button } from '../button';
import Chevron from '../icons/Chevron';
import MenuIcon from '../icons/MenuIcon';
import Dropdown from './support/Dropdown';
import CloseXIcon from '../icons/CloseXIcon';
import docText from '../../i18n/documentText.json';
// import ShoppingCartIcon from '../icons/ShoppingCartIcon';
import MobileNavContent from './support/MobileNavContent';
import { useLayout } from '../../context/Layout/useLayout';
import type { NavRoute, SubNavigation } from '../../routes';
import { Link, NavLink, useLocation } from 'react-router-dom';
import MobileNavContainer from './support/MobileNavContainer';
import { NAV_DESKTOP_BREAKPOINT, NAV_TABLET_BREAKPOINT } from './config';
import TabletSubNavigationContent from './support/TabletSubNavigationContent';
import DesktopSubNavigationContent from './support/DesktopSubNavigationContent';
import { useEffect, useCallback, useMemo, useState, type MouseEvent } from 'react';

export interface NavBarProps {
  navRoutes: NavRoute;
}

const NavBar = ({ navRoutes }: NavBarProps) => {
  const location = useLocation();
  const { windowInnerWidth } = useLayout();

  const [navOpen, setNavOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<keyof typeof navRoutes | null>(null);

  /** useLayout hook uses scss-standardized breakpoints, while this component's layout needs tailored breakpoints */
  const desktop = useMemo(() => windowInnerWidth >= NAV_DESKTOP_BREAKPOINT, [windowInnerWidth]);
  const tablet = useMemo(
    () => windowInnerWidth >= NAV_TABLET_BREAKPOINT && windowInnerWidth < NAV_DESKTOP_BREAKPOINT,
    [windowInnerWidth]
  );
  const mobile = useMemo(() => windowInnerWidth < NAV_TABLET_BREAKPOINT, [windowInnerWidth]);

  /** Close the nav if the viewport changes to tablet or desktop  */
  useEffect(() => {
    if (!mobile) setNavOpen(false);
  }, [mobile]);

  /** Close the dropdown if the viewport size changes */
  useEffect(() => {
    setOpenDropdown(null);
  }, [desktop, tablet, mobile]);

  /** Close the nav if the route changes, so that the user can see the new page */
  useEffect(() => {
    setNavOpen(false);
  }, [location]);

  const SubNavigation = useCallback(
    (route: string, routeObj: NavRoute[keyof NavRoute]) => {
      if (!routeObj.subNavigation) return null;

      return (
        <>
          <Chevron color="#8738b5" />

          {openDropdown === route && (
            <Dropdown onClose={() => setOpenDropdown(null)}>
              {tablet && <TabletSubNavigationContent subNavigation={navRoutes[route].subNavigation as SubNavigation} />}

              {desktop && (
                <DesktopSubNavigationContent subNavigation={navRoutes[route].subNavigation as SubNavigation} />
              )}
            </Dropdown>
          )}
        </>
      );
    },
    [desktop, navRoutes, openDropdown, tablet]
  );

  const NavLinks = useMemo(
    () => (
      <nav className="nav-bar-links">
        {Object.keys(navRoutes).map((route: string) =>
          navRoutes[route].visibleInNav ? (
            <div style={{ position: 'relative' }} key={route}>
              {navRoutes[route].subNavigation ? (
                <Button
                  variant="outlined"
                  className="nav-bar-link"
                  onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
                    /** Prevent clicks of this button from dismissing their corresponding dropdown */
                    if (route !== openDropdown) return;
                    e.stopPropagation();
                  }}
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                    setOpenDropdown(route);
                  }}
                >
                  {navRoutes[route].name}
                  {SubNavigation(route, navRoutes[route])}
                </Button>
              ) : (
                <NavLink className="nav-bar-link" to={navRoutes[route].path ?? '/'}>
                  {navRoutes[route].name}
                </NavLink>
              )}
            </div>
          ) : null
        )}

        {/* Hide For MVP */}
        {/* <Button className="shopping-cart-icon" onClick={() => undefined} variant="icon">
          <ShoppingCartIcon color="#8738b5" size={13.33} />
        </Button> */}
      </nav>
    ),
    [navRoutes, SubNavigation, openDropdown]
  );

  return (
    <section className="nav">
      {/* Spacer to push below the height of the nav */}
      <div className="nav-bar-spacer" />

      <div className="nav-bar">
        {!mobile && (
          <div className="banner">
            <>
              <p>{docText.topBanner.text1}</p>
              {desktop && <p>{docText.topBanner.text2}</p>}
              <p>{docText.topBanner.text3}</p>
            </>
          </div>
        )}

        {/* Top Nav */}
        <div className="nav-bar-content">
          <Link className="nav-bar-container-logo" to="/">
            <Logo colorVariant="black" />
          </Link>
          <nav className="nav-bar-links">
            {desktop && NavLinks}

            {mobile ? (
              <>
                <Button onClick={() => setNavOpen(!navOpen)} variant="icon">
                  {navOpen ? <CloseXIcon color="#667085" size={14} /> : <MenuIcon color="#667085" size={14} />}
                </Button>
                {/* Hide For MVP */}
                {/* <Button onClick={() => undefined} variant="icon">
                  <ShoppingCartIcon color="#8738b5" size={13.33} />
                </Button> */}
              </>
            ) : (
              <Button className="nav-cta-button" linkTo="/book-online">
                Book Online
              </Button>
            )}
          </nav>

          {tablet && <nav className="tablet-nav-links">{NavLinks}</nav>}
        </div>
      </div>

      {/* Mobile Nav */}
      {mobile && (
        <MobileNavContainer open={navOpen}>
          <MobileNavContent />
        </MobileNavContainer>
      )}
    </section>
  );
};

export default NavBar;
