import './Card.scss';
import type { FC, HTMLProps, ReactNode } from 'react';

export type CardProps = HTMLProps<HTMLDivElement> & {
  children: ReactNode;
};

const Card: FC<CardProps> = ({ children, ...props }) => (
  <div {...props} className={`card ${props.className ? props.className : ''}`}>
    {children}
  </div>
);

export default Card;
