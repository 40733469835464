import './Image.scss';
import { forwardRef } from 'react';
import classNames from 'classnames';
import { type Image as ImageType } from 'components/Document/types/Document';
import { useDocumentContentStyle } from 'pages/procedures/hooks/useDocumentContentStyle';

const Image = forwardRef<HTMLImageElement, Omit<ImageType, 'contentType'>>(
  ({ src, imagesDirectory, ...props }, ref) => {
    const styleProp = useDocumentContentStyle({ style: props.style });

    return (
      <img
        {...props}
        ref={ref}
        style={styleProp}
        className={classNames('image')}
        src={`${process.env.PUBLIC_URL}/${imagesDirectory}/${src}`}
      />
    );
  }
);

export default Image;
