import { useRef, useState, useEffect } from 'react';
import { useLayout } from '../context/Layout/useLayout';
import { useStateWithSetStateCallback } from './useStateWithSetStateCallback';

/**
 * @description
 * Ensures that the min-heights of multiple elements of unknown / varying
 * height are equal to the height of the tallest included element to ensure
 * horizontal content alignment of ref'd / forwardRef'd elements.
 *
 * Each update consists of two React reconciliation cycles:
 * 1) Set the min-height of all elements to 0 so that the computed (auto) height of each element can be collected.
 * 2) Set the min-height of all elements to the max of the computed heights from the previous step.
 * 
 * The whole point is that the min-heights of all elements are equal to the height of the element with the
 * most content, so you'll need to set the height property of each element to 'auto'. The hook
 * manages an array of refs, so you'll need to index into the array of refs to set the ref of each element
 * or forwardRef'd component.
 * 
 * Transform this:
 * ```
 * -----------   -----------   ------------
 * | some    |   | lots    |   | slightly |
 * | content |   | and     |   | more     |
 * -----------   | lots    |   | content  |
 *               | of      |   ------------
 *               | content |   
 *               -----------
 * ```
 * into this:
 * ```
 * -----------   -----------   ------------
 * | some    |   | lots    |   | slightly |
 * | content |   | and     |   | more     |
 * |         |   | lots    |   | content  |
 * |         |   | of      |   |          |
 * |         |   | content |   |          |
 * -----------   -----------   ------------
 * ```
 * Usage:
 * ```
 *  const { refs, minHeight } =
 *    useManageMultiElementDynamicContentHeight({
        numberOfElements: someData.length,
      });

    // ...

    return (
      <>
        {someData.map((data, index) => (
          <SomeElementOrForwardRefExoticComponent
            style={{ height: 'auto', minHeight: `${minHeight}px` }
            ref={(el) => (refs.current[index] = el)}
          />
        ))}
      </>
    )
 * ```
 */
export const useManageMultiElementDynamicContentHeight = ({ numberOfElements }: { numberOfElements: number }) => {
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [minHeight, setMinHeight] = useStateWithSetStateCallback<number | null>(null);

  const { windowInnerWidth } = useLayout();
  const refs = useRef(new Array(numberOfElements));

  useEffect(() => {
    if (refs.current.some((ref) => !ref)) return;
    if (!shouldUpdate) return;

    /** Prevent repeated updates */
    setShouldUpdate(false);

    /** Once the min-height is set to 0, the computed height of the content can be collected--the max of which is the new min-height. */
    setMinHeight(0, () => {
      setMinHeight(Math.max(...refs.current.map((el) => el.clientHeight)));
    });
  }, [minHeight, setMinHeight, shouldUpdate, windowInnerWidth]);

  /** Trigger updates when the viewport size changes. */
  useEffect(() => {
    setShouldUpdate(true);
  }, [windowInnerWidth]);

  return {
    refs,
    minHeight,
  };
};
