import './Logo.scss';
import { type FC } from 'react';
import LogoSVG from './LogoSVG';
import docText from '../../i18n/documentText.json';

type Props = {
  colorVariant?: 'white' | 'black';
};

const Logo: FC<Props> = ({ colorVariant = 'black' }) => (
  <div className="logo" aria-label={docText.footer.section1.logoAltText}>
    <LogoSVG textColor={colorVariant} />
  </div>
);

export default Logo;
