import './Blurb.scss';
import type { FC, ReactElement } from 'react';
import StyledIcon, { type StyledIconProps } from '../../../../components/styledIcon';

type Props = {
  /** The heading of the blurb */
  heading: string;
  /** The blurb's styled icon */
  Icon: ReactElement<StyledIconProps>;
  /** The blurb content */
  paragraph: string;
};

const Blurb: FC<Props> = ({ Icon, heading, paragraph }) => (
  <div className="blurb">
    <StyledIcon innerSize={40} outerSize={56} svg={Icon} />
    <h2>{heading}</h2>
    <p>{paragraph}</p>
  </div>
);

export default Blurb;
