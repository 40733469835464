import './Hero.scss';
import { Button } from '../../../../components/button';
import docText from '../../../../i18n/documentText.json';
import { useHighlightedHeader } from '../../../../hooks/useHighlightedHeader';

const Hero = () => {
  const { HighlightedHeader } = useHighlightedHeader({ colorNthAmountOfEndWords: 2, text: docText.home.header.h1 });

  return (
    <section className="homepage-hero">
      <div className="hero-container">
        <div className="column full-height">
          <div className="column">
            {HighlightedHeader}
            <p>{docText.home.header.h2}</p>
            <Button linkTo="/about#appointment" smooth hashLink>
              {docText.home.header.button}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
