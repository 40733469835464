import './CovidVisitationProtocol.scss';
import Document from 'components/Document';
import docText from 'i18n/documentText.json';
import { Document as DocumentType } from 'components/Document/types/Document';

const CovidVisitationProtocol = () => {
  return (
    <div className="covid-visitation-protocol">
      <Document
        iconsDirectory={'./'}
        imagesDirectory={'./'}
        content={docText.covidVisitationProtocol.layout as DocumentType}
      />
    </div>
  );
};

export default CovidVisitationProtocol;
