import './SubNavigation.scss';
import { useState } from 'react';
import SubRoutes from '../MobileSubRoutes';
import type { SubNavigation } from '../../../../routes';

export type Props = {
  subNavigation: SubNavigation;
  /** A function to be called when the underlying Accordion's open / closed state changes */
  onOpenStateChange?: (open: boolean) => void;
  /** The initial open state of the underlying Accordion; default false */
  initialOpenState?: boolean;
  /** Whether the underlying Accordion is expandable / collapsible; default true */
  collapsible?: boolean;
};

const SubNavigationComponent = ({ subNavigation, onOpenStateChange, initialOpenState, collapsible }: Props) => {
  const [open, setOpen] = useState(initialOpenState ?? false);

  return (
    <ul className="subnavigation-list">
      {Object.keys(subNavigation).map((key: string) => (
        <li
          key={key}
          className={`subnavigation-list-item ${open ? 'open' : 'closed'} ${
            subNavigation[key].subRoutes ? 'has-subroutes' : ''
          }`}
        >
          {subNavigation[key].subRoutes ? (
            <SubRoutes
              setOpen={(open) => {
                if (!collapsible) return;
                setOpen(open);
              }}
              collapsible={collapsible}
              initialOpenState={initialOpenState}
              onOpenStateChange={onOpenStateChange}
              subNavigationItem={subNavigation[key]}
            />
          ) : (
            <div className="flex">
              <div className="icon-container">{subNavigation[key].icon}</div>
              <a className="subnavigation-link" href={subNavigation[key].path}>
                {subNavigation[key].name}
              </a>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default SubNavigationComponent;
