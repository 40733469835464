import './Textarea.scss';
import { v4 as uuid } from 'uuid';
import type { InputProps } from '../../types/InputProps';
import { type FC, InputHTMLAttributes, useState } from 'react';
import { useInputLabelContrastColor } from '../../hooks/useInputLabelContrastColor';

const TextInput: FC<InputHTMLAttributes<HTMLTextAreaElement> & InputProps> = ({
  label,
  error,
  labelColor,
  ContainerProps,
  ...props
}) => {
  /** If an id is not provided, ensure that one is generated, so that the label is accessible */
  const [id] = useState(() => props.id || `input-${uuid()}`);

  const computedLabelColor = useInputLabelContrastColor(labelColor);

  return (
    <div className="textarea" {...ContainerProps}>
      <label htmlFor={id} style={{ color: computedLabelColor }}>
        {label}
      </label>
      <textarea id={id} {...props} />
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default TextInput;
