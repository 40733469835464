import './home.scss';
import { useMemo } from 'react';
import Hero from './assemblies/Hero';
import { navRoutes } from '../../routes';
import StyledIcon from 'components/styledIcon';
import { Button } from '../../components/button';
import { Link } from 'react-router-dom';
import ImageSection from 'components/ImageSection';
import IconSection from './assemblies/IconSection';
import docText from '../../i18n/documentText.json';
import ToothClean from 'components/icons/ToothClean';
import ToothEnamel from 'components/icons/ToothEnamel';
import Testimonials from '../../assemblies/Testimonials';
import ToothSmiling from 'components/icons/ToothSmiling';
import ToothMedical from 'components/icons/ToothMedical';
import ToothBrowser from 'components/icons/ToothBrowser';
import doctorsWorking from '../../svg/AD-Homepage-image.png';
import ToothSpeechBubble from 'components/icons/ToothSpeechBubble';
import { useHighlightedHeader } from '../../hooks/useHighlightedHeader';
import womanWearingBlueTop from '../../svg/AD-Homepage-smile-quiz.png';

export const icons = [
  {
    icon: <ToothClean color="#ffffff" />,
    subHeader: 'Cleanings & Prevention',
    linkTo: '/procedures/cleanings-and-prevention',
  },
  {
    icon: <ToothSmiling color="#ffffff" />,
    subHeader: 'Cosmetic Dentistry',
    linkTo: '/procedures/cosmetic-dentistry',
  },
  {
    icon: <ToothSpeechBubble color="#ffffff" />,
    subHeader: 'Free Consultation',
    linkTo: '/contact-us',
  },
  {
    icon: <ToothMedical color="#ffffff" />,
    subHeader: 'Restorations',
    linkTo: '/procedures/restorations',
  },
  {
    icon: <ToothEnamel color="#ffffff" />,
    subHeader: 'Mouth Guards',
    linkTo: '/procedures/mouth-guards',
  },
  {
    icon: <ToothBrowser color="#ffffff" />,
    subHeader: 'All Services',
    linkTo: '/about',
  },
];

const Home = () => {
  const { HighlightedHeader: SmileAnalysisHeading } = useHighlightedHeader({
    text: docText.home.header3.h1,
    colorNthAmountOfEndWords: 2,
  });

  const IconGrid = useMemo(
    () =>
      icons.map(({ icon, subHeader, linkTo}, index) => (
        <Link key={index}role="link"  to={linkTo} >
          <div   className="icon-grid-item">
            <StyledIcon outerBackgroundColor="#51226D" innerBackgroundColor="#6C2D91" svg={icon} />
            <h2 className="icon-grid-item-name">{subHeader}</h2>
          </div>
        </Link>
      )),
    []
  );

  return (
    <main className="homepage">
      <Hero />

      <ImageSection imageSrc={doctorsWorking} imageAlt="Dentists at work with a patient" imagePlacement="left" style={{height: '421px', width: '536px'}}>
        <div className="about-our-practice-content">
          <h1 className="h1">{docText.home.header2.h1}</h1>
          <p className="paragraph">{docText.home.header2.h2}</p>
          <Button linkTo={navRoutes.about.path} children={<p>{docText.home.header2.button}</p>} />
        </div>
      </ImageSection>

      <IconSection
        HeaderContent={
          <>
            <h2 className="section-superHeader">{docText.home.appointment.preHeader}</h2>
            <h1 className="section-header">{docText.home.appointment.h1}</h1>
            <h2 className="section-subHeader">{docText.home.appointment.h2}</h2>
            <Button className="appointment-button" linkTo="/about#appointment" hashLink smooth>
              {docText.home.appointment.button}
            </Button>
          </>
        }
      >
        {IconGrid}
      </IconSection>

      <ImageSection
        imagePlacement="right"
        imageSrc={womanWearingBlueTop}
        className="smile-analysis-section"
        imageAlt="Woman wearing blue top gesturing with open hand"
      >
        <div className="smile-analysis-content">
          {SmileAnalysisHeading}
          <p className="paragraph">{docText.home.header3.h2}</p>
          <Button linkTo={navRoutes.smileAnalysis.path}>{docText.home.header3.button}</Button>
        </div>
      </ImageSection>

      <Testimonials testimonials={docText.testimonials.content} />

      {/* Hidden For MVP */}
      {/* <ButtonSection
        shape={'flat'}
        preText={docText.home.featured.superHeader}
        header={docText.home.featured.h1}
        paragraphs={docText.home.featured.h2}
        colorNthAmountOfEndWords={1}
        sectionButton={<Button linkTo={navRoutes.shop.path} children={<p>{docText.home.featured.button}</p>} />}
        summaryPlacement="left"
        buttonPlacement={'right'}
        spaceBetween
      >
        <ImageLink shape={'square'} imageLinkArray={shopImageLinkArray} gridColumns="row" size="medium" />
      </ButtonSection> */}
    </main>
  );
};

export default Home;
