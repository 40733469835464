import { FC, ReactNode } from 'react';

export type StyledIconProps = {
  /** An SVG expressed as a React component */
  svg: ReactNode;
  /** CSS Color of the innermost circle around the icon */
  innerBackgroundColor?: string;
  /** CSS color of the outermost circle around the icon */
  outerBackgroundColor?: string;
  /** Height and width in px of the inner circle around the icon */
  innerSize?: number;
  /** Height and width in px of the entire circle around the icon */
  outerSize?: number;
};

const StyledIcon: FC<StyledIconProps> = ({
  svg,
  outerSize = 80,
  innerSize = 56,
  innerBackgroundColor = '#E5D2EF',
  outerBackgroundColor = '#F0E6F6',
}: StyledIconProps) => (
  <div
    className="styled-icon-outer"
    style={{
      width: `${outerSize}px`,
      height: `${outerSize}px`,
      maxWidth: `${outerSize}px`,
      minWidth: `${outerSize}px`,
      maxHeight: `${outerSize}px`,
      minHeight: `${outerSize}px`,
      backgroundColor: outerBackgroundColor,
    }}
  >
    <div
      className="styled-icon-inner"
      style={{
        width: `${innerSize}px`,
        height: `${innerSize}px`,
        maxWidth: `${innerSize}px`,
        minWidth: `${innerSize}px`,
        maxHeight: `${innerSize}px`,
        minHeight: `${innerSize}px`,
        backgroundColor: innerBackgroundColor,
      }}
    >
      {svg}
    </div>
  </div>
);

export default StyledIcon;
