import { env } from '../../../env';
import { useCallback, useState } from 'react';
// import docText from '../../../i18n/documentText.json';
import { useValidation } from '../../../hooks/useValidation';
import { useIntlPhoneNumber } from '../../../hooks/useIntlPhoneNumber';
import { Answer } from '../../../pages/smileAnalysis/components/Quiz/hooks/useQuiz';

type UseAppointmentFormHookProps = {
  smileAnalysisAnswers?: Answer[] | null;
};

export const useAppointmentForm = ({ smileAnalysisAnswers }: UseAppointmentFormHookProps) => {
  const [fullName, setFullName] = useState('');
  const [fullNameBlurred, setFullNameBlurred] = useState(false);

  const [email, setEmail] = useState('');
  const [emailBlurred, setEmailBlurred] = useState(false);

  const [message, setMessage] = useState('');
  const [messageBlurred, setMessageBlurred] = useState(false);

  const [didAttemptSubmit, setDidAttemptSubmit] = useState(false);

  const [phoneNumberBlurred, setPhoneNumberBlurred] = useState(false);
  const { region, setRegion, prefix, mask, phoneNumber, setPhoneNumber, formattedPhone } = useIntlPhoneNumber();

  // const [agreedToPrivacyPolicy, setAgreedToPrivacyPolicy] = useState(false);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(localStorage.getItem('appointmentFormSubmitted') === 'true');

  const { errors, errorsExist } = useValidation({
    didAttemptSubmit,
    rules: {
      fullName: {
        value: fullName,
        type: 'non-empty-string',
      },
      phoneNumber: {
        value: phoneNumber,
        type: 'phone-number',
        region,
      },
      email: {
        value: email,
        type: 'email',
      },
      message: {
        value: message,
        type: 'non-empty-string',
      },
      // HIDE FOR MVP
      // agreedToPrivacyPolicy: {
      //   type: 'boolean',
      //   requiredValue: true,
      //   value: agreedToPrivacyPolicy,
      //   errorMessage: docText.about.section2.form.privacyPolicyError,
      // },
    },
  });

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setDidAttemptSubmit(true);

      if (errorsExist) return;

      setLoading(true);

      const response = await fetch(`${env[window.location.origin].API_HOST}/api/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
          email,
          region,
          message,
          fullName,
          phone: formattedPhone,
          smileAnalysisAnswers: smileAnalysisAnswers?.map(({ question, answer }) => ({
            question,
            answer: answer ? 'Yes' : 'No',
          })),
        }),
      });

      setSubmitted(response.ok);

      localStorage.setItem('appointmentFormSubmitted', 'true');
    },
    [region, formattedPhone, fullName, email, message, errorsExist, smileAnalysisAnswers]
  );

  return {
    fullName,
    setFullName,
    fullNameBlurred,
    setFullNameBlurred,

    region,
    setRegion,
    prefix,
    mask,

    phoneNumber,
    setPhoneNumber,
    phoneNumberBlurred,
    setPhoneNumberBlurred,

    email,
    setEmail,
    emailBlurred,
    setEmailBlurred,

    message,
    setMessage,
    messageBlurred,
    setMessageBlurred,

    // HIDE FOR MVP
    // agreedToPrivacyPolicy,
    // setAgreedToPrivacyPolicy,

    errors,
    errorsExist,

    handleSubmit,
    didAttemptSubmit,

    loading,
    submitted,
  };
};
