import { breakpoints } from 'config/breakpoints';
import { type Context, type FC, createContext, useLayoutEffect, useState } from 'react';
import { Layouts, type LayoutContextProviderProps, type LayoutContextValue } from './types';

export const LayoutContext: Context<LayoutContextValue | undefined> = createContext<LayoutContextValue | undefined>(
  undefined
);

const LayoutContextProvider: FC<LayoutContextProviderProps> = ({ children }) => {
  const initialWindowSize = (dimension: 'width' | 'height') => {
    if (window.visualViewport) return window.visualViewport[dimension] ?? 0;
    return window[dimension === 'width' ? 'innerWidth' : 'innerHeight'] ?? 0;
  };

  const [layout, setLayout] = useState<Layouts | null>(null);
  const [windowInnerWidth, setWindowInnerWidth] = useState<number>(initialWindowSize('width'));
  const [windowInnerHeight, setWindowInnerHeight] = useState<number>(initialWindowSize('height'));

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowInnerWidth(window.innerWidth);
      setWindowInnerHeight(window.innerHeight);

      switch (true) {
        case window.innerWidth >= breakpoints.tabletMax:
          return setLayout(Layouts.Desktop);
        case window.innerWidth >= breakpoints.mobileMax:
          return setLayout(Layouts.Tablet);
        default:
          return setLayout(Layouts.Mobile);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        layout,
        windowInnerWidth,
        windowInnerHeight,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContextProvider;
