import './MobileNavContainer.scss';
import { useEffect, type ReactNode } from 'react';

export type Props = {
  open: boolean;
  children: ReactNode | ReactNode[];
};

const MobileNavContainer = ({ children, open }: Props) => {
  /** Prevent vertical scroll of the body when the mobile nav is open. */
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  return <div className={`mobile-nav-container ${open ? 'open' : 'closed'}`}>{children}</div>;
};

export default MobileNavContainer;
