import './Document.scss';
import P from './components/P';
import Pill from 'components/Pill';
import Grid from './components/Grid';
import List from './components/List';
import Span from './components/Span';
import Icon from './components/Icon';
import Image from './components/Image';
import Columns from './components/Columns';
import Heading from './components/Heading';
import { forwardRef, useMemo } from 'react';
import LineBreak from './components/LineBreak';
import type { Document as DocumentType } from 'components/Document/types/Document';

type DocumentProps = {
  content: DocumentType;
  iconsDirectory: string;
  /** Path (originating from the public directory) from which to dynamically load images */
  imagesDirectory: string;
};

const Document = forwardRef<HTMLDivElement, DocumentProps>(({ content, imagesDirectory, iconsDirectory }, ref) => {
  /** Props that must be passed to recursive documents, if applicable */
  const recursiveProps = useMemo(
    () => ({
      iconsDirectory,
      imagesDirectory,
    }),
    [iconsDirectory, imagesDirectory]
  );

  const Content = useMemo(
    () =>
      content.map((element, i) => {
        switch (element.contentType) {
          case 'span': {
            /** Omit non-DOM related props to suppress "React does not recognize the `` prop on a DOM element warnings */
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
            const { contentType, ...rest } = element;
            return <Span key={i} {...rest} />;
          }

          case 'paragraph': {
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
            const { contentType, ...rest } = element;
            return <P key={i} {...rest} />;
          }

          case 'heading': {
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
            const { contentType, ...rest } = element;
            return <Heading key={i} {...rest} />;
          }

          case 'list': {
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
            const { contentType, ...rest } = element;
            return <List key={i} {...rest} {...recursiveProps} />;
          }

          case 'grid': {
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
            const { contentType, ...rest } = element;
            return <Grid key={i} {...rest} {...recursiveProps} />;
          }

          case 'columns': {
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
            const { contentType, ...rest } = element;
            return <Columns key={i} {...rest} {...recursiveProps} />;
          }

          case 'icon': {
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
            const { contentType, ...rest } = element;
            return <Icon key={i} {...rest} iconsDirectory={iconsDirectory} />;
          }

          case 'image': {
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
            const { contentType, ...rest } = element;
            return <Image key={i} {...rest} imagesDirectory={imagesDirectory} />;
          }

          case 'linebreak': {
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
            const { contentType, ...rest } = element;
            return <LineBreak key={i} {...rest} />;
          }

          case 'tag': {
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
            const { contentType, text, ...rest } = element;
            return (
              <Pill key={i} className="tag" {...rest}>
                {text}
              </Pill>
            );
          }
        }
      }),
    [content, recursiveProps, imagesDirectory, iconsDirectory]
  );

  return (
    <div className="document" ref={ref}>
      {Content}
    </div>
  );
});

export default Document;
