import { useCallback, useState } from 'react';
import states from 'states-us';
import { useValidation } from '../../../hooks/useValidation';

export const useOnlinePaymentForm = () => {
  const [paymentAmount, setPaymentAmount] = useState('');
  const [paymentAmountBlurred, setPaymentAmountBlurred] = useState(false);

  const [patientFullName, setPatientFullName] = useState('');
  const [patientFullNameBlurred, setPatientFullNameBlurred] = useState(false);

  const [cardholderFirstName, setCardholderFirstName] = useState('');
  const [cardholderFirstNameBlurred, setCardholderFirstNameBlurred] = useState(false);

  const [cardholderLastName, setCardholderLastName] = useState('');
  const [cardholderLastNameBlurred, setCardholderLastNameBlurred] = useState(false);

  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine1Blurred, setAddressLine1Blurred] = useState(false);

  const [city, setCity] = useState('');
  const [cityBlurred, setCityBlurred] = useState(false);

  const [state, setState] = useState('CO');
  const [stateBlurred, setStateBlurred] = useState(false);

  const [postalCode, setPostalCode] = useState('');
  const [postalCodeBlurred, setPostalCodeBlurred] = useState(false);

  const [didAttemptSubmit, setDidAttemptSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const { errors, errorsExist } = useValidation({
    didAttemptSubmit,
    rules: {
      patientFullName: {
        value: patientFullName,
        type: 'non-empty-string',
      },
      paymentAmount: {
        value: paymentAmount,
        type: 'non-empty-string', // TODO: maybe better way to validate this
      },
      cardholderFirstName: {
        value: cardholderFirstName,
        type: 'non-empty-string',
      },
      cardholderLastName: {
        value: cardholderLastName,
        type: 'non-empty-string',
      },
      addressLine1: {
        value: addressLine1,
        type: 'non-empty-string',
      },
      city: {
        value: city,
        type: 'non-empty-string',
      },
      state: {
        value: state,
        type: 'non-empty-string', // TODO: maybe better way to validate this
      },
      postalCode: {
        value: postalCode,
        type: 'postal-code-5-digit'
      },
    },
  });

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setDidAttemptSubmit(true);

      if (errorsExist) return;

      setLoading(true);

      const URL = `https://www.eprocessingnetwork.com/cgi-bin/wo/order.pl?ePNAccount=05131064&BillCompany=ONLINE+PAYMENT&BillCountry=USA&ItemCost=${encodeURIComponent(
        paymentAmount
      )}&ItemQty=1&ItemDesc=${encodeURIComponent(patientFullName)}&BillFirstName=${encodeURIComponent(
        cardholderFirstName
      )}&BillLastName=${encodeURIComponent(cardholderLastName)}&BillAddress=${encodeURIComponent(
        addressLine1
      )}&BillCity=${encodeURIComponent(city)}&BillState=${encodeURIComponent(state)}&BillZip=${encodeURIComponent(
        postalCode
      )}&URL=http%3A%2F%2Fwww.altitudedental.com%2F&go=Continue+to+Secure+Payment+Form`;

      window.location.href = URL;
    },
    [
      city,
      state,
      postalCode,
      errorsExist,
      addressLine1,
      paymentAmount,
      patientFullName,
      cardholderLastName,
      cardholderFirstName,
    ]
  );

  return {
    paymentAmount,
    setPaymentAmount,

    paymentAmountBlurred,
    setPaymentAmountBlurred,

    patientFullName,
    setPatientFullName,

    patientFullNameBlurred,
    setPatientFullNameBlurred,

    cardholderFirstName,
    setCardholderFirstName,

    cardholderFirstNameBlurred,
    setCardholderFirstNameBlurred,

    cardholderLastName,
    setCardholderLastName,

    cardholderLastNameBlurred,
    setCardholderLastNameBlurred,

    addressLine1,
    setAddressLine1,

    addressLine1Blurred,
    setAddressLine1Blurred,

    city,
    setCity,

    cityBlurred,
    setCityBlurred,

    stateOptions: states.map(({ abbreviation }) => abbreviation),
    state,
    setState,

    stateBlurred,
    setStateBlurred,

    postalCode,
    setPostalCode,

    postalCodeBlurred,
    setPostalCodeBlurred,

    errors,
    errorsExist,

    handleSubmit,
    didAttemptSubmit,

    loading,
  };
};
