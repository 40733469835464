import './MobileSubRoutes.scss';
import Accordion from '../Accordion';
import { useCallback, type FC } from 'react';
import Chevron from '../../../icons/Chevron';
import type { SubNavigation, SubRoute } from '../../../../routes';

type Props = {
  collapsible?: boolean;
  initialOpenState?: boolean;
  setOpen: (open: boolean) => void;
  onOpenStateChange?: (open: boolean) => void;
  subNavigationItem: SubNavigation[keyof SubNavigation];
};

/** MOBILE */
const MobileSubRoutes: FC<Props> = ({
  collapsible,
  subNavigationItem,
  initialOpenState,
  setOpen,
  onOpenStateChange,
}) => {
  const onAccordionOpenStateChange = useCallback(
    (open: boolean) => {
      setOpen(open);
      if (onOpenStateChange) onOpenStateChange(open);
    },
    [onOpenStateChange, setOpen]
  );

  return (
    <Accordion
      collapsible={collapsible}
      initialOpenState={initialOpenState}
      onOpenStateChange={onAccordionOpenStateChange}
      parentContent={
        <div className="flex">
          <div className="icon-container">{subNavigationItem.icon}</div>
          <a className="subnavigation-link" href={subNavigationItem.path}>
            {subNavigationItem.name}
          </a>
        </div>
      }
    >
      <ul className="subroutes-list">
        {subNavigationItem.subRoutes && (
          <>
            {Object.keys(subNavigationItem.subRoutes as SubRoute).map((subRoute: string) => (
              <li key={subRoute} className="subroute-list-item">
                <Chevron color="#344054" />
                <a className="subnavigation-link" href={(subNavigationItem.subRoutes as SubRoute)[subRoute].path}>
                  {(subNavigationItem.subRoutes as SubRoute)[subRoute].labelText}
                </a>
              </li>
            ))}
          </>
        )}
      </ul>
    </Accordion>
  );
};

export default MobileSubRoutes;
