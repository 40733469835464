import { type ZodError, z } from 'zod';
import { useCallback, useMemo } from 'react';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const NON_EMPTY_STRING_ERROR_MESSAGE = 'Please fill out this field';
const INVALID_EMAIL_ERROR_MESSAGE = 'Please enter a valid email address';
const INVALID_PHONE_NUMBER_ERROR_MESSAGE = 'Please enter a valid phone number';

export type StringFieldTypes = 'non-empty-string' | 'email';

export type PhoneNumberValidationRule = {
  type: 'phone-number';
  /** The value to validate */
  value: string;
  region: string; // Two-letter country code
};

export type BooleanValidationRule = {
  type: 'boolean';
  /** The value to validate */
  value: boolean;
  /** The value required for the validation rule to be satisfied */
  requiredValue: boolean;
  /** Error message; while the error message is generally straightforward
   * for other types of validation such as an invalid phone number, boolean
   * validation isn't specific enough to the user and needs some additional
   * context.
   */
  errorMessage: string;
};

export type StringValidationRule = {
  type: StringFieldTypes;
  /** The value to validate */
  value: string;
};

export type PostalCode5DigitValidationRule = {
  type: 'postal-code-5-digit';
  /** The value to validate */
  value: string;
};

export type FieldValidationRule =
  | StringValidationRule
  | BooleanValidationRule
  | PhoneNumberValidationRule
  | PostalCode5DigitValidationRule;

export type Form = {
  didAttemptSubmit: boolean;
  rules: {
    [key: string]: FieldValidationRule;
  };
};

export const useValidation = (form: Form) => {
  const validateNonEmpty = useCallback(({ value }: FieldValidationRule) => {
    const nonEmptySchema = z.string().min(1, { message: NON_EMPTY_STRING_ERROR_MESSAGE });

    try {
      nonEmptySchema.parse(value);
    } catch (error) {
      return (error as ZodError).issues[0].message;
    }

    return null;
  }, []);

  const validatePhoneNumber = useCallback(({ value, region }: PhoneNumberValidationRule): string | null => {
    const schema = z
      .string()
      .min(1, { message: NON_EMPTY_STRING_ERROR_MESSAGE })
      .refine(
        (value) => {
          try {
            return phoneUtil.isValidNumber(phoneUtil.parse(value, region));
          } catch (error) {
            return false;
          }
        },
        {
          message: INVALID_PHONE_NUMBER_ERROR_MESSAGE,
        }
      );

    try {
      schema.parse(value);
    } catch (error) {
      return (error as ZodError).issues[0].message;
    }

    return null;
  }, []);

  const validateEmailAddress = useCallback(({ value }: FieldValidationRule): string | null => {
    const schema = z.string().min(1, { message: NON_EMPTY_STRING_ERROR_MESSAGE }).email(INVALID_EMAIL_ERROR_MESSAGE);

    try {
      schema.parse(value);
    } catch (error) {
      return (error as ZodError).issues[0].message;
    }

    return null;
  }, []);

  const validateBoolean = useCallback(
    ({ value, errorMessage, requiredValue }: BooleanValidationRule) => (value === requiredValue ? null : errorMessage),
    []
  );

  const validatePostalCode5Digit = useCallback(({ value }: FieldValidationRule) => {
    const schema = z
      .string()
      .min(1, { message: NON_EMPTY_STRING_ERROR_MESSAGE })
      .refine(
        (value) => {
          const postalCodeRegex = /^\d{5}$/;
          return postalCodeRegex.test(value);
        },
        {
          message: 'Please enter a valid 5-digit postal code',
        }
      );

    try {
      schema.parse(value);
    } catch (error) {
      return (error as ZodError).issues[0].message;
    }

    return null;
  }, []);

  /** ... validate other stuff as needed */

  /** Iterate through form validation rules provided by hook consumer, test the values according to the rules passed, and construct dictionary of error messages. */
  const errors = useMemo(
    () =>
      Object.keys(form.rules).reduce(
        (
          acc: {
            [key: string]: string | null;
          },
          key: keyof typeof form.rules
        ) => {
          const rule = form.rules[key];

          switch (true) {
            case rule.type === 'non-empty-string':
              acc[key] = validateNonEmpty(rule);
              break;

            case rule.type === 'phone-number':
              acc[key] = validatePhoneNumber(rule as PhoneNumberValidationRule);
              break;

            case rule.type === 'email':
              acc[key] = validateEmailAddress(rule);
              break;

            case rule.type === 'boolean':
              acc[key] = validateBoolean(rule as BooleanValidationRule);
              break;

            case rule.type === 'postal-code-5-digit':
              acc[key] = validatePostalCode5Digit(rule as PostalCode5DigitValidationRule);
              break;

            default:
              throw new Error(`Unknown field type ${rule.type}`);
          }

          return acc;
        },
        {} as { [key: string]: string | null }
      ),
    [form, validateEmailAddress, validateNonEmpty, validatePhoneNumber, validateBoolean, validatePostalCode5Digit]
  );

  const errorsExist = useMemo(() => Object.values(errors).some((error) => error !== null), [errors]);

  return {
    errors,
    errorsExist,
  };
};
