import './CountryCodeAdornment.scss';

import Chevron from '../../../../components/icons/Chevron';
import { type ChangeEvent, type FC, useState } from 'react';
import { PhoneNumberUtil } from 'google-libphonenumber';

type Props = {
  value: string;
  prefix: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
};

const CountryCodeAdornment: FC<Props> = ({ value, onChange, prefix }) => {
  const [regions] = useState(PhoneNumberUtil.getInstance().getSupportedRegions());

  return (
    <div className="text-input-select-adornment">
      <select className="text-input-select" onChange={onChange} value={value}>
        {regions.map((region) => (
          <option key={region} value={region}>
            {region}
          </option>
        ))}
      </select>
      <div className="select-value">{value}</div>

      <Chevron color="#667085" />

      {prefix && <div className="prefix">{prefix} </div>}
    </div>
  );
};

export default CountryCodeAdornment;
