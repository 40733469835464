import './DiscoverMore.scss';
import { type FC, useMemo } from 'react';
import docText from 'i18n/documentText.json';
import { useLayout, Layouts } from 'context/Layout';
import type { DiscoverMoreProcedure } from './types';
import DiscoverMoreListItem from './components/DiscoverMoreListItem';

type DiscoverMoreProps = {
  procedures: DiscoverMoreProcedure[];
};

const DiscoverMore: FC<DiscoverMoreProps> = ({ procedures }) => {
  const { layout } = useLayout();

  /** The design spec calls for three procedures to be displayed; pick them at random and map over them with UI */
  const Procedures = useMemo(() => {
    const shuffled = procedures
      .map((procedure) => ({ procedure, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort);

    const [first, second, third] = shuffled;

    return [
      ...(first ? [first.procedure] : []),
      ...(second ? [second.procedure] : []),

      /** On tablet, the design spec calls for only two procedures; exclude the third */
      ...(layout === Layouts.Tablet || !third ? [] : [third.procedure]),
    ].map((procedure) => <DiscoverMoreListItem key={procedure.name} procedure={procedure} />);
  }, [procedures, layout]);

  return (
    <section className="discover-more">
      <h2>{docText.procedures.discoverMore.heading}</h2>
      <p>{docText.procedures.discoverMore.paragraph}</p>

      <ul>{Procedures}</ul>
    </section>
  );
};

export default DiscoverMore;
