import type { IconProps } from './IconProps';

const DownloadIcon = ({ color = '#344054' }: IconProps) => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none">
    <path
      d="M16.1665 16.5H1.1665M13.6665 8.16667L8.6665 13.1667M8.6665 13.1667L3.6665 8.16667M8.6665 13.1667V1.5"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DownloadIcon;
