import { Link } from 'react-router-dom';
import { HashLink, type HashLinkProps } from 'react-router-hash-link';
import { type ReactNode, type CSSProperties, type HTMLAttributes, useMemo } from 'react';

export type SharedProps = {
  /** Button width in px; 'auto' if not specified */
  width?: number;
  /** Button height in px; default: 48 */
  height?: number;
  /** Custom className(s), space-separated */
  className?: string;
  /** Whether the button is disabled; pointer-events: none; for links, disabled="true" for buttons */
  disabled?: boolean;
  /** Specify computed button styles from callsite */
  style?: CSSProperties;
  /** Button content */
  children: ReactNode | ReactNode[];
  /** Adds a button adornment to the left of the specified children */
  adornmentLeft?: ReactNode | ReactNode[];
  /** Adds a button adornment to the right of the specified children */
  adornmentRight?: ReactNode | ReactNode[];
  /** Button appearance variants; default: 'solid' */
  variant?: 'outlined' | 'solid' | 'underline' | 'icon';
};

export type ButtonProps = HTMLAttributes<HTMLButtonElement> &
  SharedProps &
  (
    | {
        /** Default: 'button' (not browser default of 'submit') */
        type?: 'button' | 'reset';
        /** If included, differentiates Button as a button element; explicitly accepts and returns `any` because the responsibility of type-restricting the callback should fall on the consuming component. */
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        onClick?: (...args: any[]) => any;
      }
    | {
        type: 'submit';
        onClick?: never;
      }
  );

export type HashLinkButtonProps = HashLinkProps & {
  /** If included, differentiates Button as a react-router-dom hash link */
  hashLink?: boolean;
};

export type LinkProps = HTMLAttributes<HTMLAnchorElement> &
  SharedProps &
  HashLinkButtonProps & {
    /** If included, differentiates Button as a react-router-dom link */
    linkTo: string;
  };

export type Props = ButtonProps | LinkProps;

export function Button({ children, ...props }: Props) {
  const isButton = useMemo(() => 'onClick' in props || 'type' in props, [props]);

  const ButtonContent = useMemo(
    () => (
      <>
        {props.adornmentLeft && <span className="button-adornment-left">{props.adornmentLeft}</span>}
        {children && <span className="button-content">{children}</span>}
        {props.adornmentRight && <span className="button-adornment-right">{props.adornmentRight}</span>}
      </>
    ),
    [children, props.adornmentLeft, props.adornmentRight]
  );

  const style = useMemo(
    () => ({
      height: props.height ?? 48,
      width: props.width ?? 'fit-content',

      /** Disabled styles */
      ...(props.disabled && 'linkTo' in props
        ? {
            opacity: '0.4',
            cursor: 'not-allowed' as const,
            pointerEvents: 'none' as const,
          }
        : {}),

      ...(props.disabled && isButton ? { cursor: 'not-allowed', opacity: '0.4' } : {}),

      ...props.style,
    }),
    [props, isButton]
  );

  const className = useMemo(
    () => `button ${props.variant ?? 'solid'} ${props.className ?? ''}`,
    [props.variant, props.className]
  );

  if (isButton) {
    const {
      type,
      onClick,

      /** Exclude from DOM by referencing before ...rest */
      adornmentLeft, // eslint-disable-line @typescript-eslint/no-unused-vars
      adornmentRight, // eslint-disable-line @typescript-eslint/no-unused-vars

      ...rest
    } = props as ButtonProps;

    return (
      <button {...rest} role="button" style={style} onClick={onClick} className={className} type={type ?? 'button'}>
        {ButtonContent}
      </button>
    );
  }

  if ('linkTo' in props) {
    const {
      hashLink,
      linkTo,

      /** Exclude from DOM by referencing before ...rest */
      adornmentLeft, // eslint-disable-line @typescript-eslint/no-unused-vars
      adornmentRight, // eslint-disable-line @typescript-eslint/no-unused-vars

      ...rest
    } = props;

    if (hashLink) {
      return (
        <HashLink role="link" {...rest} style={style} className={className} to={linkTo}>
          {ButtonContent}
        </HashLink>
      );
    }

    return (
      <Link role="link" {...rest} to={linkTo} style={style} className={className}>
        {ButtonContent}
      </Link>
    );
  }

  throw new Error('Button must either have a linkTo or type prop.');
}
