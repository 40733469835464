import Logo from '../components/Logo';
import SocialMediaLink from './socialMediaLink';
import docText from '../i18n/documentText.json';
import type { HyperlinkSchema } from '../types';

import TwitterLogo from '../svg/twitter-logo-white.svg';
import FacebookLogo from '../svg/facebook-logo-white.svg';
import LinkedInLogo from '../svg/linkedin-logo-white.svg';
import InstagramLogo from '../svg/instagram-logo-white.svg';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-grid">
        <section style={{ gridArea: 'socials', justifySelf: 'center' }}>
          <div data-testid="footer-logo-container" className="footer-logo-container">
            <Logo colorVariant="white" />
          </div>
          <p className="footer-tagline">{docText.footer.section1.taglineText}</p>
          <div className="footer-social-media-links">
            <SocialMediaLink
              labelText={docText.footer.section1.socialMediaLinks.facebook.labelText}
              link={docText.footer.section1.socialMediaLinks.facebook.link}
              svg={FacebookLogo}
            />
            <SocialMediaLink
              labelText={docText.footer.section1.socialMediaLinks.twitter.labelText}
              link={docText.footer.section1.socialMediaLinks.twitter.link}
              svg={TwitterLogo}
            />
            <SocialMediaLink
              labelText={docText.footer.section1.socialMediaLinks.instagram.labelText}
              link={docText.footer.section1.socialMediaLinks.instagram.link}
              svg={InstagramLogo}
            />
            <SocialMediaLink
              labelText={docText.footer.section1.socialMediaLinks.linkedIn.labelText}
              link={docText.footer.section1.socialMediaLinks.linkedIn.link}
              svg={LinkedInLogo}
            />
          </div>
        </section>

        <section className="footer-links-section" style={{ gridArea: 'links' }}>
          <h3 className="footer-section-heading">{docText.footer.section2.h1}</h3>

          <nav>
            <ul className="footer-link-list">
              {Object.keys(docText.footer.section2.links)
                /** Hide non-MVP links */
                .filter((key: string) => !(docText.footer.section2.links as HyperlinkSchema)[key].hidden)
                .map((key: string) => (
                  <li className="footer-link-list-item" key={key}>
                    <a href={(docText.footer.section2.links as HyperlinkSchema)[key].link}>
                      {(docText.footer.section2.links as HyperlinkSchema)[key].labelText}
                    </a>
                  </li>
                ))}
            </ul>
          </nav>
        </section>

        <section className="footer-contact-section" style={{ gridArea: 'contact' }}>
          <h3 className="footer-section-heading">{docText.footer.section3.h1}</h3>
          <ul className="footer-link-list">
            <li className="footer-link-list-item">
              <p>{docText.footer.section3.addressLine1}</p>
              <p>{docText.footer.section3.addressLine2}</p>
            </li>

            <li className="footer-link-list-item">{docText.footer.section3.hours1}</li>
            <li className="footer-link-list-item">{docText.footer.section3.hours2}</li>
            <li className="footer-link-list-item">{docText.footer.section3.hours3}</li>
            <li className="footer-link-list-item">{docText.footer.section3.hours4}</li>
            <li className="footer-link-list-item">{docText.footer.section3.phone}</li>
            <li className="footer-link-list-item">{docText.footer.section3.fax}</li>
          </ul>
        </section>
      </div>

      <section className="footer-bottom" style={{ gridArea: 'terms-of-service' }}>
        <nav className="footer-terms-of-service">
          <ul className="footer-link-list">
            {/* HIDE FOR MVP */}
            {/* <li className="footer-link-list-item">
              <a href={docText.footer.termsOfServiceLinks.termsOfService.link}>
                {docText.footer.termsOfServiceLinks.termsOfService.labelText}
              </a>
            </li>
            <li className="footer-link-list-item">
              <a href={docText.footer.termsOfServiceLinks.privacyPolicy.link}>
                {docText.footer.termsOfServiceLinks.privacyPolicy.labelText}
              </a>
            </li> */}
            <li className="footer-link-list-item">
              <a href={docText.footer.termsOfServiceLinks.covidVisitationProtocol.link}>
                {docText.footer.termsOfServiceLinks.covidVisitationProtocol.labelText}
              </a>
            </li>
          </ul>
        </nav>

        <p className="footer-attribution-text">
          <span>{docText.footer.copyright.text} </span>
          <span>{docText.footer.attribution.text} </span>
          <a href={docText.footer.attribution.link}>{docText.footer.attribution.linkText}</a>
        </p>
      </section>
    </footer>
  );
}

export default Footer;
