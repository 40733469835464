import { useContext } from 'react';
import { LayoutContext } from './LayoutContextProvider';

export const useLayout = () => {
  const context = useContext(LayoutContext);

  if (!context) throw new Error('useLayout must be used within a LayoutContextProvider');

  return context;
};
