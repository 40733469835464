import { useMemo } from 'react';

export type UseHighlightedHeaderHookProps = {
  /** The treated text */
  text: string;
  /** Number of words to which to add a highlight of color */
  colorNthAmountOfEndWords?: number;
};

export const useHighlightedHeader = ({ text, colorNthAmountOfEndWords }: UseHighlightedHeaderHookProps) => {
  const treatedText = useMemo(() => {
    if (!colorNthAmountOfEndWords) return null;
    const headerArray = text.split(' ');

    return {
      highlighted: headerArray.splice(headerArray.length - colorNthAmountOfEndWords).join(' '),
      nonHighlighted: headerArray.slice(0, headerArray.length).join(' '),
    };
  }, [text, colorNthAmountOfEndWords]);

  const HighlightedHeader = useMemo(() => {
    return (
      <h1 className="section-header">
        {treatedText?.highlighted ? (
          <>
            <>{treatedText?.nonHighlighted} </>
            <span key={1} className="highlighted">
              {treatedText?.highlighted}
            </span>
          </>
        ) : (
          text
        )}
      </h1>
    );
  }, [treatedText, text]);

  return {
    HighlightedHeader,
  };
};
