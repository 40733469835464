import './BookFreeConsultation.scss';
import docText from '../../../../i18n/documentText.json';
import CallUsButton from '../../../../assemblies/CallUsButton';
import BookFreeConsultationImage from './images/BookFreeConsultation.png';
import HeroBackground from '../../../../components/Hero/components/HeroBackground';

const BookFreeConsultation = () => (
  <HeroBackground>
    <section className="book-free-consultation-section">
      <h1>{docText.about.section5.h1}</h1>
      <p>{docText.about.section5.p}</p>
      <CallUsButton />
      <img src={BookFreeConsultationImage} alt={docText.about.section5.imageAltText} />
    </section>
  </HeroBackground>
);

export default BookFreeConsultation;
